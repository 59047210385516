import React from 'react';

import { ONBOARDING_PAGE_KEYS } from './constant';
import { useOnboarding } from './provider';
import SetPasswordPage from './set_password/set_password_page';
import SignupPage from './sign_up/sign_up_page';
import EmailConfirmPage from './verify_email/verify_email_page';

const OnboardingRoute = () => {
  const { page } = useOnboarding();

  if (page === ONBOARDING_PAGE_KEYS.VERIFY_EMAIL) {
    return <EmailConfirmPage />;
  }

  if (page === ONBOARDING_PAGE_KEYS.SET_PASSWORD) {
    return <SetPasswordPage />;
  }

  return <SignupPage />;
};

export default React.memo(OnboardingRoute);
