import { TID } from '@src/types/common';
import { AuditScan } from '@src/types/operational_dashboard/audit_scan'; // Adjust the import path as necessary
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from '../helpers';

interface IGetLatestAuditScansParams {
  businessId?: TID;
}

interface IGetLatestAuditScansResponse {
  auditScans: AuditScan[];
}

interface IRunAuditScanParams {
  businessId: TID;
}

const getLatestAuditScans = (params: IGetLatestAuditScansParams): Promise<AuditScan> => {
  return apiGet('/api/v1/audit_scans/latest', { business_id: params.businessId })
    .then((data) => {
      const response = <IGetLatestAuditScansResponse>camelizeKeys(data);
      return response.auditScans[0] as AuditScan;
    });
};

const runAuditScan = (params: IRunAuditScanParams):Promise<{}> => {
  return apiPost(
    '/api/v1/audit_scans',
    underscoreKeys({ business_id: params.businessId }),
  ).then((data) => {
    const response = camelizeKeys(data) as AuditScan;
    return response;
  });
};

export {
  getLatestAuditScans,
  runAuditScan,
  IGetLatestAuditScansParams,
  IGetLatestAuditScansResponse,
  IRunAuditScanParams,
};
