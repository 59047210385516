import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TAmount } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { useCategorySplitsModal } from '@src/components/common_v2/category_splits_modal/category_splits_modal';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';
import StaticInput from '@src/components/ui_v2/inputs/static_input';

import { IAddAdjustmentValues } from '../schema';
import Value from './category_splits_value';
import { ICategorySplitsData } from './schema';

interface ICategorySplitsFieldProps extends IWrapperProps
{
  adjustmentAmount: TAmount,
  value: IAddAdjustmentValues['categorySplits'],
  onChange: (splits: IAddAdjustmentValues['categorySplits']) => void,
  summaryTitle?: string,
  isBusinessReadonly?: boolean,
  rowData?: ITransactionServiceDocument
}

const CategorySplitsField = ({
  adjustmentAmount,
  value,
  onChange,
  summaryTitle,
  isBusinessReadonly,
  rowData,
  ...props
}: ICategorySplitsFieldProps): JSX.Element => {
  const business = useBusinessContext();

  const handleDone = useCallback((data: ICategorySplitsData) => {
    onChange(data.splits);
  }, [onChange]);

  const splitModal = useCategorySplitsModal({ onDone: handleDone });

  const [wrapperProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <splitModal.Component
        adjustmentAmount={ adjustmentAmount }
        business={ business }
        isBusinessReadonly={ isBusinessReadonly }
        isReadonly={ rowData?.state === 'verified' }
        rowData={ rowData }
        splits={ value }
        summaryTitle={ summaryTitle }
        { ...splitModal.props }
      />
      <StaticInput style={ summaryTitle === 'Invoice' ? { height: 'fit-content' } : {} }>
        <Value summaryTitle={ summaryTitle } value={ value } onEdit={ splitModal.open } />
      </StaticInput>
    </Wrapper>
  );
};

export default React.memo(CategorySplitsField);
