import React from 'react';

import SignupForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const SignupPage = () => {
  return (
    <OnboardingPage title="Welcome to Docyt">
      <SignupForm formId="sign-up-form"/>
    </OnboardingPage>
  );
};

export default React.memo(SignupPage);
