export const DEVICE_COOKIE_NAME = 'device_uuid';

const getDeviceName = () => {
  try {
    const userAgent = navigator.userAgent;
    let version: any;
    let deviceNameArray = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(deviceNameArray[1])) {
      version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return `IE ${version[1] || ''}`;
    }

    if (deviceNameArray[1] === 'Chrome') {
      version = userAgent.match(/\bOPR|Edge\/(\d+)/);
      if (version !== null) {
        return `Opera ${version[1]}`;
      }
    }

    if (deviceNameArray[2]) {
      deviceNameArray = [deviceNameArray[1], deviceNameArray[2]];
    } else {
      deviceNameArray = [navigator.appName, navigator.appVersion, '-?'];
    }

    if ((version = userAgent.match(/version\/(\d+)/i)) !== null) {
      deviceNameArray.splice(1, 1, version[1]);
    }

    return `${deviceNameArray[0]} ${deviceNameArray[1]}`;
  } catch (error) {
    return 'Unknown Browser';
  }
};

const getDeviceUUID = () => {
  let currentDate = new Date().getTime();

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (matchValue) => {
    const composedValue = (currentDate + Math.random() * 16) % 16 | 0;
    currentDate = Math.floor(currentDate / 16);
    return (matchValue === 'x' ? composedValue : (composedValue & 0x3) | 0x8).toString(16);
  });
};

const setCookie = (name: string, value: string, days: number) => {
  let expires = '';

  if (days !== null) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');

  for (let cookie of cookies) {
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null;
};

const eraseCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export { getDeviceName, getDeviceUUID, setCookie, getCookie, eraseCookie };
