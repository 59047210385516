import React from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { clientBusinessSettingsPath } from '@src/routes';
import { TID } from '@src/types/common';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { Button } from '@src/components/ui_v2/buttons';

const CompleteSetupButton = ({ businessId }: {businessId: TID}) => {
  const { refetch:fetchBusinessServicesInfo } = useGetBusinessServicesInfo(businessId, {
    enabled: false,
  });

  const handleClick = async () => {
    const { data: info } = await fetchBusinessServicesInfo();
    if (info && info.accountingFirmBusinessId) {
      backboneNavigateToUrl(clientBusinessSettingsPath(businessId, info.accountingFirmBusinessId));
    }
  };

  return (
    <Button size="compact" variant="outline" onClick={ handleClick }>Complete Setup</Button>
  );
};

export default CompleteSetupButton;
