import React, { useCallback, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import { useCreateMappingRevenueReportModal } from './create_mapping_revenue_report_action_modal';
import Form from './form';
import { IChangeMappingValues } from './schema';

import styles from './styles.module.scss';

interface IChangeMappingRevenueReportModalProps extends IUseModalProps {
}

const ChangeMappingRevenueReportModal = ({
  isOpen,
  onDone,
  onCancel,
  ...props
}: IChangeMappingRevenueReportModalProps) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [createMapping, setCreateMapping] = useState(false);
  const ImportExistingMapping = useCallback((data: IChangeMappingValues) => {
    // TODO Create function for get data existing mapping
    console.log(data);
    onDone();
  }, [onDone]);

  const createMappingRevenueReportModal = useCreateMappingRevenueReportModal();

  const createNewMapping = useCallback(() => {
    setCreateMapping(true);
    createMappingRevenueReportModal.open();
    onDone();
  }, [createMappingRevenueReportModal, onDone]);

  return (
    <>
      <Modal
        show={ isOpen }
        title="Change Mapping"
        { ...props }
      >
        <>
          <Modal.Body>
            <p className={ styles['description-text'] }>
              New Revenue Mapping will be updated and the previous mapping will be disabled.
              You can select an already existing mapping or create a new mapping. If you choose
              to create a new mapping, your current mapping will be saved in our logs
            </p>
            <Form
              formId="select_existing_mapping_form"
              onSubmit={ ImportExistingMapping }
              onValidationChange={ setIsFormValid }
            />
            <div className={ styles['divider-or'] }>
              <span>OR</span>
            </div>
            <div className={ styles['wrapper-button'] }>
              <Button variant="link" onClick={ createNewMapping }>Create New Mapping</Button>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ onCancel }
            >
              Go Back
            </Button>
            <Button
              disabled={ !isFormValid }
              type="submit"
              variant="primary"
              onClick={ onDone }
            >
              Continue
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      {createMapping && (
        <createMappingRevenueReportModal.Component
          { ...createMappingRevenueReportModal.props }
        />
      )}
    </>
  );
};

const useChangeMappingRevenueReportModal = makeUseModal(ChangeMappingRevenueReportModal);

export {
  useChangeMappingRevenueReportModal,
  ChangeMappingRevenueReportModal as default,
};
