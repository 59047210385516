/* eslint-disable import/order */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import AiChatSendButton from './chat_input_send_button';
import { useAiChatBotContext } from '../context/ai_chat_context';
import styles from '../styles.module.scss';
import TextAreaWIthSuffixIcon from './textarea_with_suffix_icon';
import { useDebouncedCallback } from '@src/hooks/utils';

interface IChatInputProps {
  loader: boolean;
  setLoader: (value: boolean) => void;
}

const ChatInput = ({ loader, setLoader }: IChatInputProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { startConversation, activeChannelID, sendUserMessage } = useAiChatBotContext();

  const handleMessageSend = useCallback(async (message: string) => {
    if (activeChannelID && message) {
      setLoader(true);
      await sendUserMessage(message);
      setInputValue('');
    } else if (message) {
      setLoader(true);
      startConversation({ message });
      setInputValue('');
    }
  }, [activeChannelID, startConversation, sendUserMessage, setLoader]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!activeChannelID && e.target.value.length === 1) {
        startConversation({});
      }
      setInputValue(e.target.value);
    },
    [activeChannelID, startConversation],
  );

  const handleSuffixClick = useCallback(() => {
    handleMessageSend(inputValue.trim());
  }, [inputValue, handleMessageSend]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        const value = inputValue.trim();
        if (value) {
          handleMessageSend(value);
        }
      }
    },
    [inputValue, handleMessageSend],
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      const minHeight = 42;
      const maxHeight = 150;

      textarea.style.height = `${42}px`;
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);
      const currentHeight = textarea.offsetHeight;

      if (Math.abs(newHeight - currentHeight) > 5) {
        textarea.style.height = `${newHeight}px`;
      } else if (currentHeight < minHeight) {
        textarea.style.height = `${minHeight}px`;
      }
    }
  };
  const debouncedAdjustHeight = useDebouncedCallback(adjustHeight, [inputValue], 10);
  useEffect(() => {
    debouncedAdjustHeight();
  }, [debouncedAdjustHeight, inputValue]);

  return (
    <div className={ styles['chat-bottom-section'] }>
      <div className={ styles['chat-send-container'] }>
        <TextAreaWIthSuffixIcon
          ref={ textareaRef }
          hideClear
          autoComplete="off"
          className={ styles['text-area'] }
          disabled={ loader }
          placeholder="Ask a question..."
          suffixIcon={
            <AiChatSendButton isActive={ !!inputValue } onClick={ handleSuffixClick } />
          }
          value={ inputValue }
          wrapperClasses={ styles['text-area-wrapper'] }
          onChange={ handleInputChange }
          onKeyDown={ handleKeyDown }
        />
      </div>
    </div>
  );
};

export default memo(ChatInput);
