import React from 'react';

import { useRecoilValue } from 'recoil';

import { IWidget } from '@src/types/dashboards';
import { currencyLocaleFormatter } from '@src/utils/currency';

import { DefaultTargets } from '@src/components/business_dashboards/edit_targets/default_targets';
import Table from '@src/components/ui_v2/table';

import { toggleTargetsState } from '../../atoms';
import { numberFormatterMaker } from '../charts/chart_options';

import styles from './styles.module.scss';

interface ITableRowProps {
  widget: IWidget;
  targets: Record<string, number>;
  standardCategoryId: number;
}

const TableRow = ({ widget, targets, standardCategoryId }: ITableRowProps): JSX.Element => {
  const getDefaultTarget = (identifier: string) => {
    const defaultTarget = DefaultTargets[standardCategoryId.toString()];
    if (!defaultTarget) return 0;

    return defaultTarget[identifier]?.value || 0;
  };

  const showTargets = useRecoilValue(toggleTargetsState);

  const target = showTargets ? (targets[widget.identifier] || getDefaultTarget(widget.identifier)) : 0;

  const monthlyData = widget.dataSets?.[0]?.values || [];
  const unit = widget.dataSets?.[0]?.unit;

  const formatValue = (value: number) => {
    if (unit === window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.DOLLAR) {
      return currencyLocaleFormatter(value);
    }
    const formatter = numberFormatterMaker(unit);
    return formatter(value);
  };

  const renderCell = (data: { value: number, id: string }) => {
    const changeValuePercentage = ((data.value - target) / target) * 100;
    const changeClass = changeValuePercentage > 0 ? 'positive-change' : 'negative-change';
    const changeValue = changeValuePercentage
      ? `(${changeValuePercentage > 0 ? '+' : ''}${changeValuePercentage.toFixed(2)}%)` : '';

    return (
      <div className={ styles['value-container'] }>
        <span>
          {formatValue(data.value)}
          {showTargets && changeValue && <span className={ styles[changeClass] }>{changeValue}</span>}
        </span>
      </div>
    );
  };

  return (
    <Table.Row>
      <Table.TextCell className="line-item-cell">
        {widget.name}
      </Table.TextCell>
      {monthlyData.map((data) => (
        <Table.TextCell key={ data.id } className="value-cell">
          {renderCell(data)}
        </Table.TextCell>
      ))}
    </Table.Row>
  );
};

export default TableRow;
