/* global AWS */

export class UploadToS3Service {
  static async uploadSingleFileAndUpdate(file: File, document: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('original_file_name', file.name);
    formData.append('file_content_type', file.type);
    formData.append('storage_size', file.size.toString());

    const response = await fetch(`/api/v1/documents/${document.id}/upload_attachment`, {
      method:      'PUT',
      credentials: 'include',
      body:        formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Upload failed');
    }

    const data = await response.json();
    return data.document;
  }

  static async uploadFileToS3({ file, s3ObjectKey, document }: {
    file: File,
    s3ObjectKey: string,
    document: any
  }) {
    // 1. Configure AWS
    const s3 = new AWS.S3({
      params: {
        Bucket: window.configData.bucketName,
      },
    });

    AWS.config.update({
      region: window.configData.aws_main_bucket_region,
    });

    // 2. Wait for credentials
    await new Promise<void>((resolve, _reject) => {
      (AWS.config.credentials as AWS.CognitoIdentityCredentials).get(() => {
        resolve();
      });
    });

    // 3. Upload to S3
    const params = {
      Bucket:      window.configData.bucketName,
      Key:         s3ObjectKey || `documents/${document.id}/${file.name}`,
      Body:        file,
      ContentType: file.type,
    };

    await new Promise((resolve, _reject) => {
      s3.upload(params, (err, data) => {
        if (err) _reject(err);
        else resolve(data);
      });
    });
  }
}
