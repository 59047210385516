import React, { useCallback } from 'react';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import EditMappingIcon from '@src/components/utils/icomoon/edit_mapping';

import { useChangeMappingRevenueReportModal } from '../modal/change_mapping_revenue_report_action_modal';

interface IChangeMappingRevenueReportActionProps {
  report: IRevenueServiceDocument,
}

const ChangeMappingRevenueReportAction = ({
  report,
}: IChangeMappingRevenueReportActionProps) => {
  const changeMappingRevenueReportModal = useChangeMappingRevenueReportModal();

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const changeMapping = () => {
    console.log(report);
    changeMappingRevenueReportModal.open();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={ handleClick }>
      <changeMappingRevenueReportModal.Component
        { ...changeMappingRevenueReportModal.props }
      />
      <ActionsDropdown.Action
        icon={ <EditMappingIcon fontSize={ 20 } /> }
        title="Change Mapping"
        onSelect={ changeMapping }
      />
    </div>
  );
};

export default ChangeMappingRevenueReportAction;
