import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useRunAuditScan } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { TID } from '@src/types/common';
import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { useConfirmAuditModal } from '@src/components/operational_dashboard/components/confirm_audit_modal';
import ActivationButton from '@src/components/operational_dashboard/components/custom_cells/business_cell_action_btn/activate_business_button';
import CompleteSetupButton from '@src/components/operational_dashboard/components/custom_cells/business_cell_action_btn/complete_setup_button';
import AuditScanButton from '@src/components/operational_dashboard/components/custom_cells/business_cell_action_btn/run_audit_scan';
import { isInSetupStatus } from '@src/components/operational_dashboard/helper/functional';
import { IBusinessCommonProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';
import ImgIcon from '@src/components/utils/img_icons/img_icon';

import styles from './style.module.scss';

type IBusinessCellProps = IBusinessCommonProps & {
  auditScan?: AuditScanDetails;
}

const BusinessCell = ({
  displayName,
  legalName,
  icon,
  setupStatus,
  businessId,
  firmSuiteOnboardingState,
  booksGptOnboardingState,
  isSetupCompleted,
  auditScan,
}: IBusinessCellProps) => {
  const { mutate: runAuditScan } = useRunAuditScan();

  const { Component: ConfirmAuditModal, open: openConfirmAuditModal, props: confirmAuditModalProps } = useConfirmAuditModal({
    onDone: () => {
      runAuditScan({
        businessId: businessId as TID,
      }, {
        onError: (error) => {
          toastr.error(error.message, 'Error');
        },
      });
    },
  });

  const actionButton = useCallback(() => {
    // Show loader for ledger setup in progress
    if (firmSuiteOnboardingState === 'in_ledger_setup' || booksGptOnboardingState === 'in_ledger_setup') {
      return <ProgressActivityLoader size="large" />;
    }

    // Show activation button
    if (firmSuiteOnboardingState === 'activation_pending') {
      return <ActivationButton />;
    }

    // Show setup completion button
    const isLedgerCompleted =
      firmSuiteOnboardingState === 'import_ledger_completed'
      || booksGptOnboardingState === 'ledger_setup_completed';

    if (!isSetupCompleted && isLedgerCompleted) {
      if (businessId) {
        return <CompleteSetupButton businessId={ businessId } />;
      }
    }

    // Show audit scan related buttons
    if (isSetupCompleted) {
      return (
        <AuditScanButton
          status={ auditScan?.status }
          onRunAudit={ openConfirmAuditModal }
        />
      );
    }

    return null;
  }, [
    firmSuiteOnboardingState,
    booksGptOnboardingState,
    isSetupCompleted,
    auditScan?.status,
    openConfirmAuditModal,
    businessId,
  ]);

  return (
    <div className={ styles['business-column-container'] }>
      <ConfirmAuditModal { ...confirmAuditModalProps } title="Run Audit Scan" />
      <div className={ styles['business-column-info'] }>
        {isInSetupStatus(setupStatus) && <span className={ styles['in-setup-tag'] }>IN SETUP</span>}
        {icon ? (
          <div className={ styles['business-icon-container'] }>
            <ImgIcon
              alt="Business Icon"
              className={ styles['business-icon'] }
              src={ icon }
            />
          </div>
        )
          : <BusinessDefaultAvatarIcon className={ styles['business-icon-default'] } fontSize={ 32 } />}
        <div className={ styles['business-content'] }>
          <AppLink href={ `/businesses/${businessId}` }>
            <h3 className={ styles['business-name'] }>{displayName}</h3>
            <p className={ styles['business-type'] }>{legalName}</p>
          </AppLink>
        </div>
      </div>
      <div className={ styles['business-column-action'] }>
        {actionButton()}
      </div>
    </div>
  );
};
export default BusinessCell;
