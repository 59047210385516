import React, { memo } from 'react';

import Root from '@src/components/root';

import BookgptList from './table/bookgpt_list';

const DashboardBookgptWrapper = () => {
  return (
    <Root>
      <BookgptList />
    </Root>
  );
};
export default memo(DashboardBookgptWrapper);
