module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="page-content-wrapper">\n  <div class="item-divider">\n    <h2 class="service-main-title">My Firm</h2>\n\n    <span class="font-18 m-r-10">Business Profiles</span>\n    <span class="font-18 m-r-10">/</span>\n    <span class="font-bold font-26">');
    
      __out.push(__sanitize(this.display_name ? this.display_name : this.name));
    
      __out.push('</span>\n  </div>\n</div>\n\n\n<div class="business-detail-wrapper item-divider p-t-30">\n  <div class="row m-b-20 m-l-0 m-r-0 ');
    
      if (this.for_onboarding_setup) {
        __out.push(__sanitize('client-name-border-wrapper'));
      }
    
      __out.push('">\n    <div class="col-md-1 text-left business-avatar-wrapper border-gray p-l-0">\n      ');
    
      if (this.business_avatar) {
        __out.push('\n        <img src="');
        __out.push(__sanitize(this.business_avatar));
        __out.push('">\n      ');
      } else {
        __out.push('\n        <span class=\'icon-business-default-avatar font-64\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n      ');
      }
    
      __out.push('\n    </div>\n    <div class="col-md-9 text-left m-t-15 m-l-10">\n      <span class="font-20 font-bold">');
    
      __out.push(__sanitize(this.display_name ? this.display_name : this.name));
    
      __out.push('</span>\n    </div>\n    <div class="pull-right m-t-15">\n      <div id="edit-business-profile-region"></div>\n    </div>\n  </div>\n  <div class="font-14">\n    ');
    
      if (this.is_client) {
        __out.push('\n      <div class="detail-item">\n        <label>Display Name</label>\n        <span>');
        __out.push(__sanitize(this.display_name));
        __out.push('</span>\n      </div>\n    ');
      }
    
      __out.push('\n    <div class="detail-item">\n      <label>Business Name</label>\n      <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n    </div>\n    <div class="detail-item">\n      <label>Email</label>\n      <span>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</span>\n    </div>\n    <div class="detail-item">\n      <label>Phone Number</label>\n      <span>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n    </div>\n    <div class="detail-item">\n      <label>Address</label>\n      <span>');
    
      __out.push(__sanitize(this.address_info));
    
      __out.push('</span>\n    </div>\n  </div>\n\n  \n</div>\n\n<div class="form-group connect-form-group p-t-30">\n  <label class="field-label font-16">');
    
      __out.push(__sanitize('Connect your QuickBooks Accountant account to import clients'));
    
      __out.push('</label>\n  <div class="field-input qbo-connected">\n    <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="qbo-disconnect-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <i class=\'icon icon-3-dots font-20\'></i>\n    </button>\n    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="qbo-disconnect-action-dropdown-btn">\n      <a class="dropdown-item font-16 pointer disconnect-qbo-js">\n        <span class=\'in-red-400\'>Disconnect</span>\n      </a>\n    </div>\n  </div>\n  <div class="field-input qbo-disconnected">\n    <a class="btn-connect-qbo qbo-connect-link-medium"></a>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}