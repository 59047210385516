import React, { useState, useCallback } from 'react';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocumentRequest } from '@src/types/document_requests';
import { IDocument } from '@src/types/documents';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';

import DocumentConversationRightSideView, {
  IBankStatementResponse,
  IBalanceSheetStatementResponse,
} from './document_requests/document_conversation_right_side_view';

interface IDocumentModel {
  id: TID;
  business_id: TID;
  bank_statement: IBankStatementResponse | null;
  isBankStatementRequest: () => boolean;
  isBalanceSheetStatementRequest: () => boolean;
  isMailroomRequest: () => boolean;
  get: (key: string) => string | number | null;
}

interface IDocumentRequestConversationRightsideContainerProps {
  businessId: TID;
  model: IDocumentModel;
  bankStatement: IBankStatement;
  balanceSheetStatements: IBalanceSheetStatement[];
  documents: IDocument[];
  onDone: () => void;
  isLast: boolean;
  documentRequests: IDocumentRequest[];
}

const DocumentRequestConversationRightsideContainer = ({
  businessId,
  bankStatement,
  model,
  balanceSheetStatements,
  documents,
  isLast,
  onDone,
  documentRequests,
}: IDocumentRequestConversationRightsideContainerProps): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    window.Docyt.vent.trigger('request:item:unselected');
    onDone();
  }, [onDone]);

  if (!isVisible) {
    return null;
  }
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <SideView.Provider>
          <DocumentConversationRightSideView
            balanceSheetStatements={ balanceSheetStatements as IBalanceSheetStatementResponse[] }
            bankStatement={ bankStatement as IBankStatementResponse }
            businessId={ businessId }
            documentRequests={ documentRequests }
            documents={ documents }
            isLast={ isLast }
            model={ model }
            onClose={ handleClose }
          />
          <SideView.Render />
        </SideView.Provider>
      </BusinessProvider>
    </Root>
  );
};

export default DocumentRequestConversationRightsideContainer;
