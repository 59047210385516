import React, { useMemo, useState } from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';

import { SelectInput, TOption } from '@src/components/ui_v2/inputs';
import { ReactSelectDropdownIndicator } from '@src/components/ui_v2/inputs/react_select/helpers';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import { VendorPaymentsTable, VendorInvoicesTable } from './table';

import styles from './styles.module.scss';
interface ISelectMergeableVendorProps
{
  businessVendor: IBusinessVendor,
  serviceId: TID,
}
const viewSelectOptions : TOption[] = [
  {
    value: 'viewByPayments',
    label: 'View by Payments',
  },
  {
    value: 'viewByInvoices',
    label: 'View by Invoices',
  },
];
const VendorPayments = ({ businessVendor, serviceId }: ISelectMergeableVendorProps) => {
  const [selectedView, setSelectedView] = useState<TOption | null>(viewSelectOptions[0]);

  const section = useMemo(() => {
    return {
      businessId: businessVendor.id,
      section:    'vendor_payments',
    };
  }, [businessVendor.id]);

  return (
    <Section.Provider section={ section }>
      <HeaderRegion>
        <HeaderRegion.ActionsArea className={ styles['header-region-actions-area'] }>
          <SelectInput
            hideClear
            components={ {
              DropdownIndicator: ReactSelectDropdownIndicator,
            } }
            options={ viewSelectOptions }
            placeholder="Select View"
            styles={ {
              menu: (provided) => ({
                ...provided,
                background:   styles['dropdown-bgcolor'],
                border:       styles['dropdown-border'],
                borderRadius: styles['dropdown-border-radius'],
                boxShadow:    styles['dropdown-box-shadow'],
                padding:      styles['select-menu-padding'],
                margin:       styles['select-menu-margin'],
                width:        styles['select-menu-width'],
                zIndex:       13,
              }),
            } }
            value={ selectedView }
            onChange={ (selectedOption) => {
              setSelectedView(selectedOption);
            } }
          />
        </HeaderRegion.ActionsArea>
      </HeaderRegion>
      <TableSection>
        { selectedView?.value === 'viewByPayments' && (
          <VendorPaymentsTable
            businessVendor={ businessVendor }
            section={ section }
          />
        ) }
        { selectedView?.value === 'viewByInvoices' && (
          <VendorInvoicesTable
            businessVendor={ businessVendor }
            section={ section }
            serviceId={ serviceId }
          />
        ) }
      </TableSection>
    </Section.Provider>
  );
};
export default VendorPayments;

