import { object, string } from 'yup';

const signUpValidation = object({
  email: string()
    .trim()
    .email('Email must be a valid email.')
    .required('Email is required.'),
  fullName: string().trim().required('Full name is required.'),
});

const setPasswordValidation = object({
  password: string().test((value, validationContext) => {
    const { createError } = validationContext;

    if (!value) {
      return createError({ message: 'Password is required' });
    }

    if (!window.PASSWORD_REGEXP.test(value)) {
      return createError({ message: 'Please enter more symbols' });
    }

    return true;
  }),
  confirmPassword: string().test((value, validationContext) => {
    const {
      createError,
      parent: { password },
    } = validationContext;

    if (!value || password !== value) {
      return createError({ message: 'Please enter correct password' });
    }
    
    return true;
  }),
});

interface ISignUpFormValues {
  fullName: string;
  email: string;
}

interface ISetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

export {
  ISetPasswordFormValues,
  ISignUpFormValues,
  setPasswordValidation,
  signUpValidation,
};
