import React from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';
import Dropdown from '@src/components/ui_v2/dropdown';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import { PlusIcon, SwitchViewIcon } from '@src/components/utils/icomoon';

import { useAddBusinessClientModal } from './add_business_client/add_business_client_modal';
import { useAddInviteClientModal } from './add_invite_client/add_invite_client_modal';

import styles from './styles.module.scss';

interface IHeaderProps {
  switchToNewDashboard?: boolean,
  isMultiBusinessDashboardEnabled: boolean,
  searchQuery?: string,
  onSearched: (value: string) => void,
  onSwitchToNewDashboard: () => void,
}

const Header = ({
  switchToNewDashboard = false,
  isMultiBusinessDashboardEnabled = false,
  searchQuery,
  onSearched,
  onSwitchToNewDashboard,
}: IHeaderProps): JSX.Element => {
  const addBusinessClientModal = useAddBusinessClientModal();
  const addInviteClientModal = useAddInviteClientModal();

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['add-client-dropdown-btn'] }>
      <Button
        className={ styles['add-client-business-btn'] }
        variant="primary"
      >
        <PlusIcon />
      </Button>
    </Dropdown.ToggleButton>
  );
  return (
    <>
      <addBusinessClientModal.Component
        { ...addBusinessClientModal.props }
      />
      <addInviteClientModal.Component
        { ...addInviteClientModal.props }
      />
      <div className={ styles['header-region'] }>
        <div className={ styles['header-region-right'] }>
          {!switchToNewDashboard && (
            <>
              <SearchInput
                value={ searchQuery }
                onChange={ onSearched }
              />
              <div className={ styles['horizontal-divider'] } />
            </>
          )}
          { isMultiBusinessDashboardEnabled && (
            <div className={ styles['switch-view-button-container'] }>
              <Button
                className={ styles['switch-view-button'] }
                variant="outline"
                onClick={ onSwitchToNewDashboard }
              >
                <SwitchViewIcon className={ styles['switch-view-icon'] } fontSize={ 24 } />
                Switch to
                {' '}
                {switchToNewDashboard ? 'Old' : 'New'}
                {' '}
                View
              </Button>
            </div>
          )}
          <ActionsDropdown toggleButton={ toggleButton }>
            <ActionsDropdown.Action
              title="Add Client Business"
              onClick={ addBusinessClientModal.open }
            />
            <ActionsDropdown.Action
              title="Invite Client"
              onClick={ addInviteClientModal.open }
            />
          </ActionsDropdown>
        </div>
      </div>
    </>
  );
};

export default Header;
