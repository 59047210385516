import React from 'react';

import styles from '../styles.module.scss';

interface IOnboardingPageProps {
  title: string;
  children: React.ReactNode;
}

const OnboardingPage = ({ title,children }: IOnboardingPageProps) => {
  return (
    <div className={ styles['onboarding-container'] }>
      <div className={ styles['onboarding-heading'] }>
        <h1 className="font-24 font-bold text-center">{ title }</h1>
      </div>
      <div className="background-white sign-in-main-panel m-l-auto m-r-auto">
        { children }
      </div>
    </div>
  );
};

export default OnboardingPage;
