import React from 'react';

import Root from '@src/components/root';

import { OnboardingProvider } from './provider';
import OnboardingRoute from './route';

const SignUpPage = () => {
  return (
    <Root>
      <OnboardingProvider>
        <OnboardingRoute />
      </OnboardingProvider>
    </Root>
  );
};

export default React.memo(SignUpPage);
