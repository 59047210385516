import { useMutation } from 'react-query';

import {
  createUserAccount,
  ICreateUserAccountParams,
  resendEmail,
} from '@src/requests/signup';

const useResendEmail = () => {
  return useMutation<void, Error, string>(resendEmail);
};

const useCreateUserAccount = () => {
  return useMutation<void, Error, ICreateUserAccountParams>(createUserAccount);
};

export { useResendEmail, useCreateUserAccount };
