/* global AWS */

import { ISymmetricKey } from '@src/types/symmetric_keys';

interface IDownloadFromS3Params {
  s3ObjectKey: string,
  symmetricKey?: ISymmetricKey,
}

interface IDownloadFromS3Return {
  fileData: ArrayBuffer,
}

const downloadFromS3 = ({
  s3ObjectKey,
  symmetricKey,
}: IDownloadFromS3Params): Promise<IDownloadFromS3Return> => {
  return new Promise<IDownloadFromS3Return>((resolve, reject) => {
    // First update AWS config with cognito credentials
    AWS.config.update({ region: window.configData.cognitoRegion });

    // Configure the credentials provider
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: window.configData.cognitoPoolId,
    });

    // Wait for credentials to be refreshed
    AWS.config.credentials.get(() => {
      // Update region for S3 operations
      AWS.config.update({ region: window.configData.aws_main_bucket_region });

      const bucket = new AWS.S3();

      const params: AWS.IAWSObjectParams = {
        Bucket: window.configData.bucketName,
        Key:    s3ObjectKey,
      };

      if (symmetricKey?.key) {
        params.SSECustomerAlgorithm = window.configData.encryptionAlgorithm;
        params.SSECustomerKey = AWS.util.base64.decode(symmetricKey?.key);
      }

      bucket.getObject(params, (err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve({ fileData: data.Body });
      });
    });
  });
};

interface IUploadFileToS3Params {
  file: File,
  s3ObjectKey: string,
  symmetricKey: ISymmetricKey,
}

interface IUploadFileToS3Return {
  s3ObjectKey: string,
}

interface IUploadAttachmentToS3Return {
  Bucket: string,
  ETag: string,
  Key: string,
  Location: string,
  key: string,
}

const uploadFileToS3 = ({
  file,
  s3ObjectKey,
  symmetricKey,
}: IUploadFileToS3Params): Promise<IUploadFileToS3Return> => {
  return new Promise<IUploadFileToS3Return>((resolve, reject) => {
    AWS.config.update({ region: window.configData.cognitoRegion });
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: window.configData.cognitoPoolId,
    });

    AWS.config.credentials.get(() => {
      AWS.config.update({ region: window.configData.aws_main_bucket_region });

      const bucket = new AWS.S3({
        params: {
          region:               window.configData.aws_main_bucket_region,
          Bucket:               window.configData.bucketName,
          SSECustomerKey:       AWS.util.base64.decode(symmetricKey.key),
          SSECustomerAlgorithm: window.configData.encryptionAlgorithm,
        },
        config: {
          signatureVersion: 'v4',
        },
      });

      const s3ObjectParams = {
        Key:         s3ObjectKey,
        Body:        file,
        ContentType: file.type,
      };

      bucket.upload(s3ObjectParams, (err: Error, data) => {
        if (err) {
          reject(err);
        } else {
          resolve({ s3ObjectKey: (data.Key || data.key) as string });
        }
      });
    });
  });
};

interface IUploadImageFileToS3Params {
  file: File,
  s3ObjectKey: string,
  bucketName: string,
}

const uploadImageFileToS3 = ({
  file,
  s3ObjectKey,
  bucketName,
}: IUploadImageFileToS3Params): Promise<IUploadFileToS3Return> => {
  return new Promise<IUploadFileToS3Return>((resolve, reject) => {
    AWS.config.update({ region: window.configData.cognitoRegion });
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: window.configData.cognitoPoolId,
    });

    AWS.config.credentials.get(() => {
      AWS.config.update({ region: window.configData.aws_region });

      const bucket = new AWS.S3({
        params: {
          region: window.configData.aws_region,
          Bucket: bucketName,
        },
        config: {
          signatureVersion: 'v4',
        },
      });

      const s3ObjectParams = {
        ACL:         'public-read',
        Key:         s3ObjectKey,
        Body:        file,
        ContentType: file.type,
      };

      bucket.upload(s3ObjectParams, (err: Error, data) => {
        if (err) {
          reject(err);
        } else {
          resolve({ s3ObjectKey: (data.Key || data.key) as string });
        }
      });
    });
  });
};

const uploadAttachmentToS3 = ({
  file,
  s3ObjectKey,
  symmetricKey,
}: IUploadFileToS3Params): Promise<IUploadAttachmentToS3Return> => {
  return new Promise<IUploadAttachmentToS3Return>((resolve, reject) => {
    AWS.config.update({ region: window.configData.cognitoRegion });
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: window.configData.cognitoPoolId,
    });

    AWS.config.credentials.get(() => {
      AWS.config.update({ region: window.configData.aws_main_bucket_region });

      const bucket = new AWS.S3({
        params: {
          region:               window.configData.aws_main_bucket_region,
          Bucket:               window.configData.bucketName,
          SSECustomerKey:       AWS.util.base64.decode(symmetricKey.key),
          SSECustomerAlgorithm: window.configData.encryptionAlgorithm,
        },
        config: {
          signatureVersion: 'v4',
        },
      });
      
      const s3ObjectParams = {
        Key:         s3ObjectKey,
        Body:        file,
        ContentType: file.type,
      }

      bucket.upload(s3ObjectParams, (err: Error, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data as any);
        }
      });
    });
  });
};

export {
  downloadFromS3,
  uploadFileToS3,
  uploadImageFileToS3,
  uploadAttachmentToS3,
};
