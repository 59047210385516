/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';

import toastr from '@lib/toastr';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { useUpdateTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { getServiceDocumentSplits } from '@src/requests/accounts_payable/service_document_splits';
import { useCreateDocumentNote, useDeleteDocumentNote } from '@src/requests/all_transactions';
import { IAccountingClass } from '@src/types/accounting_class';
import { IServiceDocumentSplit } from '@src/types/accounts_payable/service_document_split';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';
import CategorySplitsField from '@src/components/reconciliation_center/match_documents/add_adjustment/category_splits_field';
import MutationStatus from '@src/components/utils/mutation_status';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import TransactionTypeSelector from './transaction_type_selector';

import styles from '../../styles.module.scss';

interface IOtherIncomeProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
  Verifiy: (boolean: boolean) => void;
  setRowData: (data: ITransactionServiceDocument) => void;
}

const OtherIncome = ({ businessId, rowData, Verifiy, setRowData }: IOtherIncomeProps) => {
  const [state, setState] = useState({
    noDocumentNeeded: rowData.noDocumentRequired || false,
    doNotLearn:       rowData.doNotLearn,
    noPushQbo:        rowData.noPushQbo,
    documentNote:     rowData.documentNote?.note || '',
    isSubmitting:     false,
  });

  const isDisabled = rowData.state === 'verified';
  const [categorySplits, setCategorySplits] = useState<ICategorySplit[]>([]);
  const [categoryLength, setCategoryLength] = useState<number>(0);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);

  const { mutateAsync: deleteDocumentNote } = useDeleteDocumentNote();
  const { mutateAsync } = useUpdateTransactionServiceDocument();
  const { mutateAsync: createDocumentNote } = useCreateDocumentNote();
  const createServiceDocumentSplits = useCreateServiceDocumentSplits();
  const { mutate } = createServiceDocumentSplits;

  const handleValueChange = useCallback(async (key: string, value: string | boolean) => {
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      const params = { id: rowData.id, [key]: value };
      const data = await mutateAsync(params);
      setRowData(data.transactionServiceDocument);
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  }, [mutateAsync, rowData.id, setRowData]);

  const handleDocumentNoteChange = useCallback(async (note: string) => {
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      const res = await createDocumentNote({ documentId: rowData.documentId, note });
      setRowData({ ...rowData, documentNote: res.documentNote });
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  }, [createDocumentNote, rowData, setRowData]);

  const handleNdnChange = useCallback(async (value: boolean) => {
    if (!value && rowData.documentNote && 'id' in rowData.documentNote) {
      await deleteDocumentNote({
        documentId: rowData.documentId,
        noteId:     rowData.documentNote.id,
      });
    }
    await handleValueChange('noDocumentRequired', value);
  }, [deleteDocumentNote, handleValueChange, rowData.documentNote, rowData.documentId]);

  const getCategorySplits = useCallback(async (): Promise<ICategorySplit[]> => {
    if (!rowData.category) {
      return [
        {
          amount:              rowData.amount,
          chartOfAccountId:    rowData.chartOfAccountId,
          chartOfAccountName:  rowData.chartOfAccount?.displayName,
          businessId,
          accountingClassId:   null,
          accountingClassName: null,
          businessName:        null,
          memo:                rowData.description,
          percentage:          100,
        },
      ];
    }

    const categories = rowData.category.split(';');
    setCategoryLength(categories.length);

    if (categories.length > 1) {
      try {
        const res = await getServiceDocumentSplits({
          documentID: rowData.documentId,
        });

        return res?.serviceDocumentSplits.map((item: IServiceDocumentSplit) => ({
          amount:              item.amount,
          chartOfAccountId:    item.chartOfAccountId,
          chartOfAccountName:  item.chartOfAccountName,
          businessId,
          accountingClassId:   item.accountingClass?.id,
          accountingClassName: item.accountingClass?.name,
          businessName:        null,
          memo:                item.memo,
          percentage:          Number(((parseFloat(item.amount || '0') / parseFloat(rowData.amount)) * 100).toFixed(2)),
        })) || [];
      } catch {
        return [];
      }
    }

    return categories.map(() => ({
      amount:             rowData.amount,
      chartOfAccountId:   rowData.chartOfAccountId,
      chartOfAccountName: rowData.chartOfAccount?.displayName,
      businessId,
      accountingClassId:  typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.id || null : null,
      accountingClassName: typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.name || null : null,
      businessName: null,
      memo:         rowData.description,
      percentage:   100,
    }));
  }, [
    rowData.category,
    rowData.documentId,
    rowData.amount,
    rowData.chartOfAccountId,
    rowData.chartOfAccount,
    rowData.accountingClass,
    rowData.description,
    businessId,
  ]);

  useEffect(() => {
    const loadCategorySplits = async () => {
      const splits = await getCategorySplits();
      setCategorySplits(splits);
    };
    loadCategorySplits();
  }, [getCategorySplits]);

  useEffect(() => {
    let disabled = true;

    if (((state.noDocumentNeeded && !state.documentNote) || !state.noDocumentNeeded) && categoryLength > 0) {
      disabled = false;
    }

    Verifiy(disabled);
  }, [categoryLength, state.noDocumentNeeded, state.documentNote, Verifiy]);

  const handleCategorySplitsChange = (splitChanges: ICategorySplit[] | undefined) => {
    if (splitChanges) {
      mutate(
        {
          documentId:            rowData.documentId,
          serviceDocumentSplits: splitChanges,
        },
        {
          onSuccess: () => {
            setCategoryLength(splitChanges.length);
            setSuccessMsg(
              splitChanges.length > 1
                ? window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SPLIT
                : window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SET,
            );
          },
        },
      );
      setCategorySplits(splitChanges);
    } else {
      setCategorySplits([]); // When splitChanges is undefined, set it to an empty array.
    }
  };

  return (
    <div key={ rowData.id }>
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <div className={ styles['sidebar-data'] }>
          <CategorySplitsField
            adjustmentAmount={ rowData.amount }
            isBusinessReadonly={ false }
            label="Category*"
            rowData={ rowData }
            summaryTitle="Invoice"
            value={ categorySplits }
            onChange={ handleCategorySplitsChange }
          />
        </div>

        <DocytAiLearnToggle
          checked={ !state.doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => handleValueChange('doNotLearn', !checked) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          documentNote={ state.documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ state.noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleDocumentNoteChange }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ state.noPushQbo }
          onChange={ (checked) => handleValueChange('noPushQbo', checked) }
        />
      </div>
    </div>
  );
};

export default OtherIncome;
