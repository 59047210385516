import React, { useMemo } from 'react';

type Context = {
  email: string;
  fullName: string;
  emailAccessToken: string;
  page: string;
  setEmail: (email: string) => void;
  setFullName: (fullName: string) => void;
  setEmailAccessToken: (emailAccessToken: string) => void;
  setPage: (page: string) => void;
};

const OnboardingContext = React.createContext<Context | null>(null);

const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const [email, setEmail] = React.useState<string>('');
  const [fullName, setFullName] = React.useState<string>('');
  const [emailAccessToken, setEmailAccessToken] = React.useState<string>('');
  const [page, setPage] = React.useState<string>('sign_up');

  const value = useMemo(
    () => ({
      email,
      fullName,
      emailAccessToken,
      page,
      setEmail,
      setFullName,
      setEmailAccessToken,
      setPage,
    }),
    [email, fullName, emailAccessToken, page, setEmail, setFullName, setEmailAccessToken, setPage]
  );

  return (
    <OnboardingContext.Provider value={ value }>
      { children }
    </OnboardingContext.Provider>
  );
};

const useOnboarding = () => {
  const context = React.useContext(OnboardingContext);

  if (!context) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  
  return context;
};

export { OnboardingContext, OnboardingProvider, useOnboarding };
