import React, { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useRecoilState } from 'recoil';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { TID } from '@src/types/common';
import { IReport, ITemplate, TReportDataPeriodType } from '@src/types/report_service/report';
import { formatFullMonthYear } from '@src/utils/date_helpers';

import Text from '@src/components/ui/text';
import { SelectInput } from '@src/components/ui_v2/inputs';
import { ReactSelectDropdownIndicator } from '@src/components/ui_v2/inputs/react_select/helpers';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import { reportDataPeriodType, reportsAccountingMethod } from '../../atoms';
import Actions from '../actions/report_detail_header_actions';
import styles from '@src/components/ui_v2/inputs/react_select/styles.module.scss';

import stylesReport from '../styles.module.scss';
import { Button } from '@src/components/ui/buttons';
import { isBasicReport } from '../hooks';

interface IBusinessReportDetailHeaderProps {
  businessId: TID;
  report: IReport;
  template?: ITemplate;
}

const options = [
  {
    label: 'Daily',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY,
  },
  {
    label: 'Monthly',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.MONTHLY,
  },
];

const allOptions = [
  {
    label: 'Daily',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY,
  },
  {
    label: 'Weekly',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY,
  },
  {
    label: 'Monthly',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.MONTHLY,
  },
];

const BusinessReportDetailHeader = ({
  businessId,
  report,
  template,
}: IBusinessReportDetailHeaderProps): JSX.Element => {
  const [selectedPeriod, setPeriod] = useRecoilState(reportDataPeriodType);
  const [accountingMethod, setAccountingMethod] = useRecoilState(reportsAccountingMethod);
  const business = useBusinessContext();
  const { data: info } = useGetBusinessServicesInfo(business.id);
  const showPeriodTypeSelect = report.periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY || (!isEmpty(report.periodTypes) && report.periodTypes.length > 1)
  const generateAvailableOptions = () => {
    if (!showPeriodTypeSelect) {
      return [];
    }
    if (!isEmpty(report.periodTypes) && report.periodTypes.length > 1) {
      return allOptions.filter((op) => report.periodTypes.includes(op.value as TReportDataPeriodType));
    }
    return options;
  }
  const availableOptions = generateAvailableOptions();

  const handleChange = useCallback(
    selectedOption => {
      setPeriod(selectedOption.value);
    },
    [setPeriod]
  );
  console.log("BusinessReportDetailHeader", report,  window.Docyt.Common.Constants);

  const switchAccountingMethod = ()=> {
    if (accountingMethod.method === window.Docyt.Common.Constants.REPORT_ACCOUNTING_METHOD.CASH) {
      setAccountingMethod({
        method: window.Docyt.Common.Constants.REPORT_ACCOUNTING_METHOD.ACCRUAL,
        timestamp: new Date().getTime(),
      });
    } else {
      setAccountingMethod({
        method: window.Docyt.Common.Constants.REPORT_ACCOUNTING_METHOD.CASH,
        timestamp: new Date().getTime(),
      });
    }
  }
  const switchAccountingMethodButton = isBasicReport(report) ? <Button bsStyle="blue-on-hover" onClick={switchAccountingMethod}>
    {accountingMethod.method === window.Docyt.Common.Constants.REPORT_ACCOUNTING_METHOD.CASH ? "Switch to Accrual Basis" : "Switch to Cash Basis"}
  </Button>: null;

  let reportTitle = report.name;
  if(isBasicReport(report)) {
    reportTitle = `${report.name} - ${accountingMethod.method === window.Docyt.Common.Constants.REPORT_ACCOUNTING_METHOD.CASH ? "Cash Basis" : "Accrual Basis"}`;
  }
  console.log("BusinessReportDetailHeader", report,  window.Docyt.Common.Constants, reportTitle);  
  return (
    <DetailsRegion.Header
      breadcrumbs={
        template && {
          title: window.Docyt.Common.Constants.REPORT_CATEGORY_NAMES[template?.category],
          href: `/businesses/${business.id}/reports?category=${template?.category}`,
        }
      }
      className={ stylesReport['blue-color'] }
      divider="horizontal"
      title={ reportTitle }
    >
      {showPeriodTypeSelect && (
        <SelectInput
          hideClear
          options={ availableOptions }
          components={{
            DropdownIndicator: ReactSelectDropdownIndicator,
          }}
          styles={{
            menu: provided => ({
              ...provided,
              alignItems: 'flex-start',
              background: styles['dropdown-bgcolor'],
              border: styles['dropdown-border'],
              borderRadius: styles['dropdown-border-radius'],
              boxShadow: styles['dropdown-box-shadow'],
              cursor: styles['select-menu-cursor'],
              display: 'flex',
              flexDirection: 'column',
              padding: styles['select-menu-padding'],
              margin: styles['select-menu-margin'],
              whiteSpace: 'nowrap',
              zIndex: Number(styles['select-menu-z-index']),
              width: 130,
            }),
            valueContainer: provided => ({
              ...provided,
              width: 100,
            }),
          }}
          value={ availableOptions.find(option => option.value === selectedPeriod) }
          onChange={ handleChange }
        />
      )}
      {switchAccountingMethodButton}
      <Actions businessId={ businessId } category={ template?.category } report={ report } />
    </DetailsRegion.Header>
  );
};

export default BusinessReportDetailHeader;
