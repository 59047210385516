import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { revenueServiceMappingPath } from '@src/routes';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import { useChangeMappingRevenueReportModal } from './change_mapping_revenue_report_action_modal';
import Form from './form_create_mapping';
import { ICreateMappingValues } from './schema';

import styles from './styles.module.scss';

interface ICreateMappingRevenueReportModalProps extends IUseModalProps {
}

const CreateMappingRevenueReportModal = ({
  isOpen,
  onDone,
  onCancel,
  ...props
}: ICreateMappingRevenueReportModalProps) => {
  const business = useBusinessContext();
  const CreateNewMapping = useCallback((data: ICreateMappingValues) => {
    // TODO Create Function New Mapping
    console.log(data);
    const url = revenueServiceMappingPath(business.id, 12345);
    Backbone.history.navigate(url, { trigger: true });
    onDone();
  }, [onDone]);

  const changeMappingRevenueReportModal = useChangeMappingRevenueReportModal();

  const handleOnCancel = useCallback(() => {
    changeMappingRevenueReportModal.open();
    onCancel();
  }, [changeMappingRevenueReportModal, onCancel]);

  return (
    <>
      <Modal
        show={ isOpen }
        title="Name New Mapping"
        { ...props }
      >
        <>
          <Modal.Body>
            <p className={ styles['description-text'] }>
              New Revenue Mapping will be updated and the previous mapping will be archived.
            </p>
            <Form
              formId="create_mapping"
              onSubmit={ CreateNewMapping }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ handleOnCancel }
            >
              Go Back
            </Button>
            <Button
              form="create_mapping"
              type="submit"
              variant="primary"
            >
              Continue
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      <changeMappingRevenueReportModal.Component
        { ...changeMappingRevenueReportModal.props }
      />
    </>
  );
};

const useCreateMappingRevenueReportModal = makeUseModal(CreateMappingRevenueReportModal);

export {
  useCreateMappingRevenueReportModal,
  CreateMappingRevenueReportModal as default,
};
