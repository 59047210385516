import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';

const ActivationButton = (): JSX.Element | null => {
  if (!window.Docyt.currentAdvisor.checkUserAccessToBookgpt()) {
    return null;
  }

  return (
    <Button size="compact" variant="outline">Activate</Button>
  );
};

export default ActivationButton;
