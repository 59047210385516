import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { Button } from '@src/components/ui_v2/buttons';

type TAllTransactionsListCategorizeActionsProps = {
  document: ITransactionServiceDocument,
}

const AllTransactionsListCategorizeActions = ({
  document,
}: TAllTransactionsListCategorizeActionsProps) => {

  const handleCategorizeActionsClick = (document: ITransactionServiceDocument) => {
    console.log(document);
  };

  return (
    <div className="display-flex justify-content-end">
      {document?.state === 'unverified' ? (
        <Button
          variant="ghost"
          onClick={ (e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              handleCategorizeActionsClick(document);
            } }
          >
            Categorize
          </Button>
      ) : <span className="in-purple-1000">Review</span>}
    </div>
  );
};

export default AllTransactionsListCategorizeActions;
