import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

interface IConfirmAuditModalProps extends IUseModalProps {
  title: string
}

const ConfirmAuditModal = ({
  title = 'Run Audit Scan',
  isOpen,
  onDone,
  onCancel,
}: IConfirmAuditModalProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        <p className="text-center font-16">
          Start Audit Scan? This can take few minutes to complete.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link"
          variant="link"
          onClick={ onCancel }
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={ onDone }
        >
          Run
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const useConfirmAuditModal = makeUseModal(ConfirmAuditModal);
