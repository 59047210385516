module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="page-content-wrapper" class="m-l-40 m-r-40">\n    <div class="item-divider">\n        <h2 class="service-main-title">Personal Profile</h2>\n    </div>\n    <div class="item-divider">\n        <div class="m-b-25">\n            <span class="font-18 font-bold">Email</span>\n            <a class="email-edit-btn pull-right pointer font-14 font-bold">Edit</a>\n        </div>\n        <div class="email-status-panel m-b-25">\n            ');
    
      if (Docyt.currentAdvisor.get('email_status') === 'verified') {
        __out.push('\n            <span class="font-18">');
        __out.push(__sanitize(Docyt.currentAdvisor.get("email")));
        __out.push('</span>\n            <span class="pull-right font-12 in-green-700"><i class="fa fa-check-circle"></i> Your email address is verified</span>\n            ');
      } else {
        __out.push('\n            <span class="font-18">');
        __out.push(__sanitize(Docyt.currentAdvisor.get("unverified_email")));
        __out.push('</span>\n            ');
      }
    
      __out.push('\n        </div>\n        ');
    
      if (Docyt.currentAdvisor.get('email_status') !== 'verified') {
        __out.push('\n        <div class="resend-email-panel p-l-20 p-r-20 p-t-15 p-b-15 font-14">\n            <p>We have sent you a verification email. Please click the link in the email to verify it.</p>\n            <a class="font-bold pointer resend-email-js">Resend Email</a>\n            ');
        if (Docyt.currentAdvisor.get('email_status') === 'verifying_new_email' && Docyt.currentAdvisor.get("email") !== null) {
          __out.push('\n                <hr/>\n                <p>Previous email: ');
          __out.push(__sanitize(Docyt.currentAdvisor.get("email")));
          __out.push('\n                <span class="pull-right font-12 in-green-700"><i class="fa fa-check-circle"></i> Verified</span>\n                </p>\n                <a class="font-bold pointer use-previous-email-js">Use Previous Email</a>\n            ');
        }
        __out.push('\n        </div>\n        ');
      }
    
      __out.push('\n        <p class="font-14 m-t-25 in-grey-950">A verified email is the method of last resort to recover your account and data.</p>\n        <hr/>\n        <p class="font-14 m-b-25 in-grey-950">\n            Besides data and account recovery, your email may be used to send you important alerts about documents expiry, document ownership changes, unread messages etc.<br/>\n            You can customise the type of email notifications you want to receive in Notification Settings.\n        </p>\n    </div>\n    <div class="item-divider">\n        <div class="m-b-25">\n            <span class="font-18 font-bold">Phone Number</span>\n            <a class="pull-right pointer font-14 font-bold phone-edit-btn">Edit</a>\n        </div>\n        <p class="font-18">');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('phone')));
    
      __out.push('</p>\n    </div>\n    ');
    
      if (Docyt.currentAdvisor.canSwitchToClient() && !Docyt.currentAdvisor.checkUserAccessToBookgpt()) {
        __out.push('\n        <div class="item-divider">\n            <div class="m-b-25">\n                <span class="font-18 font-bold">Default Profile</span>\n            </div>\n            <div>\n                <div class="font-16 m-t-25 in-grey-950"><small class="text-muted">Select your profile and land on your chosen profile upon login</small></div>\n                <div class="checkbox checkbox-primary checkbox-circle checkbox-multiline">\n                    <input type="radio" id="radioAccountingFirm" name="default_profile" class="pointer radio-default-profile" value="accounting_firm" ');
        if (Docyt.currentAdvisor.get("default_profile") === 'accounting_firm') {
          __out.push('checked');
        }
        __out.push(' >\n                    <label for="radioAccountingFirm">Accounting Firm </label>\n                </div>\n                <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n                    <input type="radio" id="radioBusiness" name="default_profile" class="pointer radio-default-profile" value="business" ');
        if (Docyt.currentAdvisor.get("default_profile") === 'business') {
          __out.push('checked');
        }
        __out.push('>\n                    <label for="radioBusiness">Business <br><small class="text-muted"></small></label>\n                </div>\n            </div>\n        </div>\n    ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}