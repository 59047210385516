import React from 'react';

import { IMonthEndClosingProps } from '@src/types/common_operational_dashboard';
import { formatDate } from '@src/utils/date_helpers';

import { auditScanTooltipData, missingBankStatementTooltipData } from '@src/components/operational_dashboard/helper';
import { ICommonProps } from '@src/components/operational_dashboard/types';
import { NotepadIcon } from '@src/components/utils/icomoon';
import EncryptedIcon from '@src/components/utils/icomoon/encrypted';

import AuditScanItem from './audit_scan_item';
import ReconciliationItem from './reconciliation_item';
import { encryptedIconColor } from './utils';

import styles from './style.module.scss';

interface IMonthEndClosingCellProps extends IMonthEndClosingProps, ICommonProps {}
const MonthEndClosingCell = ({
  missingBankStatements,
  auditScan,
  auditScanStatus,
  lastCloseDate,
  notes,
  preferences,
  setupStatus,
}: IMonthEndClosingCellProps) => {
  return (
    <div className={ `${styles['business-reconciliation-container']} ${styles['expense-header-container']}` }>
      {preferences?.monthEndClosing?.missingBankStatements
        && (missingBankStatements !== undefined ? (
          <div className={ styles['header-item'] }>
            <ReconciliationItem
              setupStatus={ setupStatus }
              tooltipData={ missingBankStatementTooltipData }
              tooltipValue={ missingBankStatements }
              value={ missingBankStatements }
            />
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            <EncryptedIcon fontSize={ 20 } inColor={ encryptedIconColor } />
          </div>
        ))}
      {preferences?.monthEndClosing?.auditScan && (auditScan !== undefined && auditScan !== null && lastCloseDate !== undefined && lastCloseDate !== null ? (
        <div className={ styles['header-item'] }>
          <AuditScanItem
            auditScanStatus={ auditScanStatus ?? 'completed' }
            lastAuditedOn={ formatDate(lastCloseDate) }
            tooltipData={ auditScanTooltipData }
            value={ auditScan }
          />
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          <EncryptedIcon fontSize={ 20 } inColor={ encryptedIconColor } />
        </div>
      ))}
      {preferences?.monthEndClosing?.lastCloseDate && (lastCloseDate !== undefined && lastCloseDate !== null ? (
        <div className={ styles['header-item'] }>
          { formatDate(lastCloseDate) }
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          <EncryptedIcon fontSize={ 20 } inColor={ encryptedIconColor } />
        </div>
      ))}

      {preferences?.monthEndClosing?.notes && (notes !== undefined ? (
        <div className={ styles['header-item'] }>
          <NotepadIcon fontSize={ 16 } />
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          <EncryptedIcon fontSize={ 20 } inColor={ encryptedIconColor } />
        </div>
      ))}
    </div>
  );
};
export default MonthEndClosingCell;
