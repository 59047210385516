import React from 'react';

import { IVendorPayment, TVendorPaymentStep } from '@src/types/vendor_payments';

import { PaymentStatus } from '@src/components/vendors/my_vendors/details/payments/options/payment_status_variant';

import VendorStatusStep from './payment_steps';

import styles from '../styles.module.scss';

type TVendorStatusProps = {
  vendorPayment: IVendorPayment,
  showTitle?: boolean,
};

const statusOrder: TVendorPaymentStep[] = [
  PaymentStatus.PAYMENT_CREATED,
  PaymentStatus.PAYMENT_MAILED,
  PaymentStatus.PAYMENT_IN_TRANSIT,
  PaymentStatus.PAYMENT_IN_LOCAL_AREA,
  PaymentStatus.PAYMENT_PROCESSED_FOR_DELIVERY,
  PaymentStatus.PAYMENT_CLEARED,
];

const statusOrderAch: TVendorPaymentStep[] = [
  PaymentStatus.PAYMENT_CREATED,
  PaymentStatus.PAYMENT_PROCESSING,
  PaymentStatus.PAYMENT_COMPLETED,
];

const VendorStatus = ({
  vendorPayment,
  showTitle = true,
}: TVendorStatusProps) => {
  const activeStatusOrder = React.useMemo(() => {
    const statusOrders = [...statusOrder];
    if (vendorPayment.paymentStatus === PaymentStatus.PAYMENT_CLEARED) {
      statusOrders.push(PaymentStatus.PAYMENT_CLEARED);
    }
    return vendorPayment.paymentMethod === 'docyt_ach' ? statusOrderAch : statusOrders;
  }, [vendorPayment]);

  const currentStatusIndex = activeStatusOrder.indexOf(
    vendorPayment.paymentStatus as TVendorPaymentStep,
  );

  return (
    <div>
      {showTitle && (
        <div className={ styles['status-title'] }>Payment Status</div>
      )}

      {activeStatusOrder.map((status, index) => {
        const isCurrentStep = index === currentStatusIndex;

        const isFinished =
        vendorPayment.paymentStatus === PaymentStatus.PAYMENT_CLEARED
        || vendorPayment.paymentStatus === PaymentStatus.PAYMENT_COMPLETED
        || vendorPayment.paymentStatus === PaymentStatus.PAYMENT_PROCESSED_FOR_DELIVERY;

        const isProcessActivated = isCurrentStep && !isFinished;
        const isProcessingStep = isCurrentStep && !isFinished;

        const isActivated = index < currentStatusIndex;
        const isChecked = index < currentStatusIndex;

        return (
          <VendorStatusStep
            key={ status }
            isActivated={ isFinished || isActivated }
            isChecked={ isFinished || isChecked }
            isProcess={ isProcessingStep }
            isProcessActivated={ isProcessActivated }
            step={ status as TVendorPaymentStep }
          />
        );
      })}
    </div>
  );
};

export default VendorStatus;
