import React from 'react';

import { TID } from '@src/types/common';
import { IIssuesProps } from '@src/types/common_operational_dashboard';

import { DisconnectedBankAccountsTooltipData, DuplicateVendorsTooltipData,
  GeneralLedgerDisconnectedTooltipData, GeneralLedgerConnectionIssuesTooltipData,
  RevenueIntegrationsTooltipData, CoaSyncErrorsData } from '@src/components/operational_dashboard/helper';
import { isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import NumberBox from './number_box';
import CorporateCardsTooltip from '../tooltips/corporate_cards_tooltip';

import styles from './style.module.scss';

interface IIssuesCellProps extends IIssuesProps, ICommonProps {
    businessId?: TID;
}
const IssueCell = ({
  disconnectedGeneralLedger,
  disconnectedRevenueIntegration,
  disconnectedBankAccounts,
  generalLedgerConnectionIssues,
  duplicateVendors,
  setupStatus,
  businessId,
  chartOfAccountSyncErrors,
}: IIssuesCellProps) => {
  if (isSetupStatusComplete(setupStatus)) {
    return <h2 className={ styles['setup-heading'] }>Complete Setup</h2>;
  }

  return (
    <div className={ `${styles['business-issue-container']} ${styles['expense-header-container']}` }>
      <div className={ styles['issue-icon-list'] }>
        <div className={ styles['header-item'] }>
          <HoverTooltipWrapper
            content={ (
            disconnectedGeneralLedger ? (
              <CorporateCardsTooltip
                businessId={ businessId }
                item={ GeneralLedgerDisconnectedTooltipData }
              />
            ) : null
            ) }
          >
            <NumberBox value={ { value: disconnectedGeneralLedger, type: 'indicator' } } />
          </HoverTooltipWrapper>
        </div>
        {!window.Docyt.currentAdvisor.checkUserAccessToBookgpt() && (
          <>
            <div className={ styles['header-item'] }>
              <HoverTooltipWrapper
                content={ (
                disconnectedRevenueIntegration
                  ? (
                    <CorporateCardsTooltip
                      businessId={ businessId }
                      item={ RevenueIntegrationsTooltipData }
                      value={ disconnectedRevenueIntegration }
                    />
                  ) : null
              ) }
              >
                <NumberBox
                  isDanger
                  value={ { value: disconnectedRevenueIntegration, type: 'number' } }
                />
              </HoverTooltipWrapper>
            </div>
            <div className={ styles['header-item'] }>
              <HoverTooltipWrapper
                content={ (
                disconnectedBankAccounts
                && (
                <CorporateCardsTooltip
                  businessId={ businessId }
                  item={ DisconnectedBankAccountsTooltipData }
                  value={ disconnectedBankAccounts }
                />
                )
              ) }
              >
                <NumberBox
                  isDanger
                  value={ { value: disconnectedBankAccounts, type: 'number' } }
                />
              </HoverTooltipWrapper>
            </div>
          </>
        )}

        <div className={ styles['header-item'] }>
          <HoverTooltipWrapper
            content={ (
              generalLedgerConnectionIssues && (
              <CorporateCardsTooltip
                businessId={ businessId }
                item={ GeneralLedgerConnectionIssuesTooltipData }
                value={ generalLedgerConnectionIssues }
              />
              )
            ) }
          >
            <NumberBox
              isWarning
              value={ { value: generalLedgerConnectionIssues, type: 'number' } }
            />
          </HoverTooltipWrapper>
        </div>
        <div className={ styles['header-item'] }>
          <HoverTooltipWrapper content={ (
           duplicateVendors && (
           <CorporateCardsTooltip
             businessId={ businessId }
             item={ DuplicateVendorsTooltipData }
             value={ duplicateVendors }
           />
           )
            ) }
          >
            <NumberBox
              isWarning
              value={ { value: duplicateVendors, type: 'number' } }
            />
          </HoverTooltipWrapper>
        </div>
        {!window.Docyt.currentAdvisor.checkUserAccessToBookgpt() && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper content={ (
            chartOfAccountSyncErrors && (
            <CorporateCardsTooltip
              businessId={ businessId }
              item={ CoaSyncErrorsData }
              value={ chartOfAccountSyncErrors }
            />
            )
              ) }
            >
              <NumberBox
                isWarning
                value={ { value: chartOfAccountSyncErrors, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
export default IssueCell;
