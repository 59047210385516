import React, { useEffect, useState } from 'react';

import { IBusinessUser } from '@src/types/business_users';
import { TID } from '@src/types/common';
import { IReport, TReportCategory } from '@src/types/report_service/report';

import { LDFeatureForBusinessEnabled } from '@src/utils/config';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import DeleteReportAction from './delete_report_action';
import EditAccessAction from './edit_access_action';
import EditMappingAction from './edit_mapping_action';
import RenameReportAction from './rename_report_action';
import ReportRefreshViewAction from './report_refresh_view_action';
import UpdateReportAction from './update_report_action';
import { useRecoilValue } from 'recoil';
import { isBasicReport } from '../hooks';
interface IReportItemActionsProps {
  businessId: TID,
  report: IReport,
  category: TReportCategory,
  users: IBusinessUser[],
  handleReportNavigation: () => void
}

const ReportItemActions = ({
  businessId,
  report,
  category,
  users,
  handleReportNavigation
}: IReportItemActionsProps): JSX.Element => {

  const [showRefreshLogOption, setShowRefreshLogOption] = useState(false);
  const basicReport = isBasicReport(report);  
  useEffect(() => {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.BUSINESS_REPORT_REFRESH_LOG_ENABLED,
      String(businessId),
    ).then((res: boolean) => {
      setShowRefreshLogOption(res);
    });
    }, [businessId, setShowRefreshLogOption]);

  return (
    <ActionsDropdown className="m-l-auto m-r-15 m-t-5">
      <RenameReportAction report={ report } />
      <EditAccessAction category={ category } report={ report } users={ users } />
      <EditMappingAction category={ category } report={ report } />
      { !basicReport && <UpdateReportAction handleReportNavigation={ handleReportNavigation } report={ report } /> }
      { showRefreshLogOption && <ReportRefreshViewAction report={ report } /> }
      <DeleteReportAction category={ category } report={ report } />
    </ActionsDropdown>
  );
};

export default ReportItemActions;
