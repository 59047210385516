import React from 'react';

import { useDashboardTableCollection } from '@src/components/operational_dashboard/hooks';
import Table from '@src/components/operational_dashboard/table/table';
import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

const BookgptList = () => {
  const view: TViewOperationalDashboard = window.Docyt.currentAdvisor.isBookgptAccountantUser() ? 'accountant' : 'business';
  const collection = useDashboardTableCollection({ view });
  return (
    <Section.Provider section={ collection?.section }>
      <div className={ styles['bookgpt-list-container'] }>
        <TableSection className={ styles['bookgpt-list-section'] }>
          <Table
            infiniteQuery={ collection.query }
            records={ collection.records }
            sorting={ collection.sorting }
            view={ view }
          />
        </TableSection>
      </div>
    </Section.Provider>
  );
};

export default React.memo(BookgptList);
