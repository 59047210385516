import React from 'react';

import { TID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';

import Text from '@src/components/ui/text';

import Header from './header';
import List from './list';
import { useQueryData } from '../hooks';

interface IReportDetailPageProps {
  businessId: TID;
  report: IReport;
}

const ReportDetail = ({ businessId, report }: IReportDetailPageProps): JSX.Element => {
  const data = useQueryData(report, 'reportDetail');
  console.log('report', report);
  console.log('ReportDetail data', data);
  return (
    <>
      <Header businessId={ businessId } report={ report } template={ data.template } />
      <List configurations={ data.configurations } items={ data.items } report={ report } />
      <Text as="div" className="m-t-18" fontVariant="semi-bold">
        Note: “-” denotes that data is unavailable for that date/period in Docyt
      </Text>
    </>
  );
};

export default ReportDetail;
