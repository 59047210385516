import React from 'react';

import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';

import Button from '@src/components/ui_v2/buttons/button';
import Form from '@src/components/ui_v2/form';

import styles from '../styles.module.scss';

interface IOnboardingFormProps {
  formId: string;
  backButtonLabel: string;
  nextButtonLabel: string;
  onClickBackButton: () => void;
  onSubmit?: SubmitHandler<any>,
  handleSubmit?: UseFormHandleSubmit<any>,
  children: React.ReactNode;
}

const OnboardingForm = ({ 
  formId,
  backButtonLabel,
  nextButtonLabel,
  onClickBackButton,
  onSubmit,
  handleSubmit,
  children
}: IOnboardingFormProps) => {
  const handleSubmitForm = handleSubmit && onSubmit ? handleSubmit(onSubmit) : () => {};

  return (
    <Form
      className={ styles['onboarding-form'] }
      id={ formId }
      onSubmit={ handleSubmitForm }
    >
      <div className={ styles['onboarding-body'] }>
        { children }
      </div>
      <div className={ styles['onboarding-footer'] }>
        <Button
          variant="link"
          onClick={ onClickBackButton }
        >
          { backButtonLabel }
        </Button>
        <Button
          type="submit"
          variant="primary"
        >
          { nextButtonLabel }
        </Button>
      </div>
    </Form>
  );
};

export default OnboardingForm;