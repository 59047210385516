import { useEffect, useMemo, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  useGetIntegrationExternalSystem,
  useGetIntegrationExternalSystemById,
} from '@src/hooks/queries/integrations/integrations';
import { TID } from '@src/types/common';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';
import { LDFeatureForBusinessEnabled } from '@src/utils/config';

interface ExternalSystem {
  label: string;
  id: number;
  connected: number;
  integrationType: string;
  configSchema: IExternalSystemPullProperties;
}

const useGetIntegrationExternalSystemData = () => {
  const business = useBusinessContext();
  const externalSystem = useGetIntegrationExternalSystem({
    businessId: business.id,
  });

  const [externalSystemList, setExternalSystemList] = useState<ExternalSystem[]>([]);

  useEffect(() => {
    const fetchExternalSystems = async () => {
      if (!externalSystem.data?.collection) return;

      const systems = await Promise.all(
        externalSystem.data.collection.map(async (value) => {
          const integrationName = `integrations-${value.name}`;
          let entity: ExternalSystem | null = null;

          if (window.configData.launch_darkly_global_features.includes(integrationName)) {
            const flagEnabled = await LDFeatureForBusinessEnabled(integrationName, String(business.id));
            if (flagEnabled) {
              entity = {
                label:           value.description,
                id:              value.id,
                connected:       0, // TODO: Update this if data is available from backend
                integrationType: value.integrationType,
                configSchema:    value.configSchema,
              };
            }
          } else {
            entity = {
              label:           value.description,
              id:              value.id,
              connected:       0, // TODO: Update this if data is available from backend
              integrationType: value.integrationType,
              configSchema:    value.configSchema,
            };
          }

          return entity;
        }),
      );

      setExternalSystemList(systems.filter((system): system is ExternalSystem => system !== null));
    };

    fetchExternalSystems();
  }, [externalSystem.data, business.id]);

  return externalSystemList;
};

const useGetIntegrationExternalSystemDataById = (id: TID) => {
  const business = useBusinessContext();
  const externalSystemById = useGetIntegrationExternalSystemById({
    businessId: business.id,
    id,
  });

  const externalSystemItem = useMemo(() => {
    return (
      externalSystemById.data?.collection || null
    );
  }, [externalSystemById.data]);

  return externalSystemItem;
};

export {
  useGetIntegrationExternalSystemData,
  useGetIntegrationExternalSystemDataById,
};
