import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { useGetPaymentProcessors } from '@src/hooks/queries/bank_account_reconciliations';
import { getServiceDocumentSplits } from '@src/requests/accounts_payable/service_document_splits';
import { IAccountingClass } from '@src/types/accounting_class';
import { IServiceDocumentSplit } from '@src/types/accounts_payable/service_document_split';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';
// eslint-disable-next-line max-len
import CategorySplitsField from '@src/components/reconciliation_center/match_documents/add_adjustment/category_splits_field/index';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import SidebarSelectInput from './sidebar_select_input';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

interface IRevenueProps {
  rowData: ITransactionServiceDocument;
  setRowData: (rowData: ITransactionServiceDocument) => void
  verify: (boolean: boolean) => void;
}

const Revenue = ({ rowData, setRowData, verify }: IRevenueProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } =
    useTxnDoc({ rowData, setRowData });

  const {
    isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo, documentRelated,
  } = commonProps;

  const business = useBusinessContext();
  const [categoryLength, setCategoryLength] = useState<number>(0);
  const [categorySplits, setCategorySplits] = useState<ICategorySplit[]>([]);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);

  const { revenueCenterEnabled } = business;
  const processorsQuery = useGetPaymentProcessors({
    businessId: business.id,
  });

  const processorOptions = useMemo(() => {
    const processors = processorsQuery.data?.paymentProcessors;
    if (!processors) return [];
    return processors.filter((i) => i.selectedInCurrentBusiness).map((i) => ({ value: String(i.id), label: i.name }));
  }, [processorsQuery.data?.paymentProcessors]);

  const processorValue = processorOptions.find((i) => i.value === String(rowData.userPaymentProcessorId));

  const createServiceDocumentSplits = useCreateServiceDocumentSplits();
  const { mutate } = createServiceDocumentSplits;

  const getCategorySplits = useCallback(async (): Promise<ICategorySplit[]> => {
    if (!rowData.category) {
      return [
        {
          amount:              rowData.amount,
          chartOfAccountId:    rowData.chartOfAccountId,
          chartOfAccountName:  rowData.chartOfAccount?.displayName,
          businessId:          business.id,
          accountingClassId:   null,
          accountingClassName: null,
          businessName:        null,
          memo:                rowData.description,
          percentage:          100,
        },
      ];
    }

    const categories = rowData.category.split(';');
    setCategoryLength(categories.length);

    if (categories.length > 1) {
      try {
        const res = await getServiceDocumentSplits({
          documentID: rowData.documentId,
        });

        return res?.serviceDocumentSplits.map((item: IServiceDocumentSplit) => ({
          amount:              item.amount,
          chartOfAccountId:    item.chartOfAccountId,
          chartOfAccountName:  item.chartOfAccountName,
          businessId:          business.id,
          accountingClassId:   item.accountingClass?.id,
          accountingClassName: item.accountingClass?.name,
          businessName:        null,
          memo:                item.memo,
          percentage:          Number(((parseFloat(item.amount || '0') / parseFloat(rowData.amount)) * 100).toFixed(2)),
        })) || [];
      } catch {
        return [];
      }
    }

    return categories.map(() => ({
      amount:             rowData.amount,
      chartOfAccountId:   rowData.chartOfAccountId,
      chartOfAccountName: rowData.chartOfAccount?.displayName,
      businessId:         business.id,
      accountingClassId:  typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.id || null : null,
      accountingClassName: typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.name || null : null,
      businessName: null,
      memo:         rowData.description,
      percentage:   100,
    }));
  }, [
    rowData.category,
    rowData.documentId,
    rowData.amount,
    rowData.chartOfAccountId,
    rowData.chartOfAccount,
    rowData.accountingClass,
    rowData.description,
    business.id,
  ]);

  useEffect(() => {
    const loadCategorySplits = async () => {
      const splits = await getCategorySplits();
      setCategorySplits(splits);
    };
    loadCategorySplits();
  }, [getCategorySplits]);

  const handleCategorySplitsChange = (splitChanges: ICategorySplit[] | undefined) => {
    if (splitChanges) {
      mutate(
        {
          documentId:            rowData.documentId,
          serviceDocumentSplits: splitChanges,
        },
        {
          onSuccess: () => {
            setCategoryLength(splitChanges.length);
            setSuccessMsg(
              splitChanges.length > 1
                ? window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SPLIT
                : window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SET,
            );
          },
        },
      );
      setCategorySplits(splitChanges);
    } else {
      setCategorySplits([]); // When splitChanges is undefined, set it to an empty array.
    }
  };

  useEffect(() => {
    let disabled = true;

    if (((!revenueCenterEnabled && categoryLength > 0) || revenueCenterEnabled) && processorValue && documentRelated) {
      disabled = false;
    }

    verify(disabled);
  }, [categoryLength, revenueCenterEnabled, processorValue, documentRelated, verify]);

  return (
    <>
      {isSubmitting && <Spinner />}
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="Payment Processor*"
          options={ processorOptions }
          placeholder="Select Payment Processor"
          value={ processorValue }
          onChange={ (val) => updateFields({ paymentProcessorId: val, userPaymentProcessorId: val }) }
        />
        {!revenueCenterEnabled
            && (
              <div className={ styles['sidebar-data'] }>
                <CategorySplitsField
                  adjustmentAmount={ rowData.amount }
                  isBusinessReadonly={ false }
                  label="Category*"
                  rowData={ rowData }
                  summaryTitle="Invoice"
                  value={ categorySplits }
                  onChange={ handleCategorySplitsChange }
                />
              </div>
            )}

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </>
  );
};

export default Revenue;
