import { number, object, string } from 'yup';

import { TID } from '@src/types/common';

interface IChangeMappingValues {
  businessId: TID | undefined
  revenueReportTypeId: TID | undefined
}
const selectChangeMappingValidation = object({
  revenueReportTypeId: number().nullable().label('Select Mapping').required(),
});

interface ICreateMappingValues {
  mappingName: string | undefined
}
const createMappingValidation = object({
  mappingName: string().required(),
});

export {
  IChangeMappingValues,
  selectChangeMappingValidation,
  ICreateMappingValues,
  createMappingValidation,
};
