import React from 'react';

import TableRowItem from '@src/components/operational_dashboard/table/table_row/table_row_item';
import Table from '@src/components/ui_v2/table';

import { IBusinessOwnerConfigFormType } from '../helper';
import {
  IDashboardData,
} from '../types';

interface IDashboardTableBodyProps {
    records: IDashboardData[];
    preferences?: IBusinessOwnerConfigFormType;
    view?: string | null;
}
const TableBody = ({
  records,
  preferences,
  view,
}: IDashboardTableBodyProps) => {
  return (
    <Table.Body>
      {records.map((columnData) => (
        <TableRowItem
          key={ columnData.id }
          columnData={ columnData }
          preferences={ preferences }
          view={ view }
        />
      ))}
    </Table.Body>
  );
};
export default TableBody;
