import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import { ICreateMappingValues, createMappingValidation } from './schema';

interface ICreateMappingProps {
  formId: string;
  onSubmit: (data: ICreateMappingValues) => void;
}

const CreateMapping = ({ formId, onSubmit }: ICreateMappingProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ICreateMappingValues>({
    defaultValues: {
      mappingName: '',
    },
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(createMappingValidation),
  });

  const handleSubmitForm = (data: ICreateMappingValues) => {
    onSubmit(data);
  };

  return (
    <Form id={ formId } onSubmit={ handleSubmit(handleSubmitForm) }>
      <Form.TextField
        error={ errors.mappingName?.message }
        label="New Map Name"
        placeholder="Type Here"
        { ...register('mappingName') }
      />
    </Form>
  );
};

export default CreateMapping;
