import React from 'react';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ResetRevenueReportAction from '@src/components/revenue_center/revenue_capture/actions/reset_report';

import AddReport from './add_report';
import ChangeMappingRevenueReportAction from './change_mapping';
import DownloadRevenueReportAction from './download_action';
import EditReport from './edit_report';
import MarkNoRevenueDayAction from './no_revenue_days/mark_no_revenue_day';
import UndoMarkNoRevenueDayAction from './no_revenue_days/undo_mark_no_revenue_day';
import ResolveIssue from './resolve_issue_action';
import { NO_REPORT_ID } from '../../utils';

interface IRevenueCaptureDocumentActionsProps {
  report: IRevenueServiceDocument,
}

const RevenueCaptureDocumentActions = ({
  report,
}: IRevenueCaptureDocumentActionsProps): JSX.Element => {
  if (report.id === NO_REPORT_ID) {
    return (
      <>
        { report.status !== 'no_revenue_day' && <AddReport report={ report } /> }
        { report.status === 'missing' && <MarkNoRevenueDayAction report={ report } /> }
        { report.status === 'no_revenue_day' && <UndoMarkNoRevenueDayAction report={ report } /> }
        <ResetRevenueReportAction report={ report } />
        <ChangeMappingRevenueReportAction report={ report } />
      </>
    );
  }

  return (
    <>
      { report.status === 'unbalanced' && <ResolveIssue report={ report } /> }
      <EditReport report={ report } />
      { report.status === 'no_revenue_day' && <UndoMarkNoRevenueDayAction report={ report } /> }
      <DownloadRevenueReportAction report={ report } />
      <ResetRevenueReportAction report={ report } />
      <ChangeMappingRevenueReportAction report={ report } />
    </>
  );
};

export default RevenueCaptureDocumentActions;
