import React, { useCallback, useState } from 'react';

import { IVendorPayment } from '@src/types/vendor_payments';

import AccrualDateLabel from './accrual_date_label';
import ChartOfAccountLabel from './chart_of_account_label';
import InvoiceNoLabel from './invoice_no_label';

import styles from '../styles.module.scss';

type TSummaryInfoItemProps = {
  vendorPayment: IVendorPayment,
  columnName: 'invoiceNo' | 'chartOfAccount' | 'accrualDate',
};

const SummaryInfoItem = ({ vendorPayment, columnName }: TSummaryInfoItemProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div className={ styles['summary-info-item'] } onMouseLeave={ handleMouseLeave }>
      {columnName === 'invoiceNo' && (
        <InvoiceNoLabel
          showTooltip={ showTooltip }
          vendorPayment={ vendorPayment }
          onMouseEnter={ handleMouseEnter }
        />
      )}
      {columnName === 'chartOfAccount' && (
        <ChartOfAccountLabel
          showTooltip={ showTooltip }
          vendorPayment={ vendorPayment }
          onMouseEnter={ handleMouseEnter }
        />
      )}
      {columnName === 'accrualDate' && (
        <AccrualDateLabel
          showTooltip={ showTooltip }
          vendorPayment={ vendorPayment }
          onMouseEnter={ handleMouseEnter }
        />
      )}
    </div>
  );
};

export default SummaryInfoItem;
