/* eslint-disable eqeqeq */
import React, { useCallback, useMemo } from 'react';

import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetLightReportsList, useGetReportTemplates } from '@src/hooks/queries/report_service/custom_reports';
import { budgetsPath, departmentReportsPath, laborReportsPath, managementReportsPath, metricsPath, revenueReportsPath, viewReportPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const ReportsItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled,
}: IServiceMenuProps) => {
  const service = useReportServiceContext();
  const business = service.business;
  const reportsQuery = useGetLightReportsList(service.id);
  const reports = useMemo(() => reportsQuery.data || [], [reportsQuery]);
  const reportTemplatesQuery = useGetReportTemplates(business.standardCategoryId);
  const templates = useMemo(() => reportTemplatesQuery.data || [], [reportTemplatesQuery]);

  const filterReports = useCallback((reportCategory) => {
    return reports.filter((report) => {
      return templates.find((t) => t.id === report.templateId && t.category === reportCategory);
    });
  }, [reports, templates]);

  const canVisibleReportService = info.isBusinessAdmin || info.canAccessReportService;
  const canVisibleMetricsService = info.isBusinessAdmin || info.hasMetricsServiceRole;

  const managementReports = filterReports('management');
  const departmentReports = filterReports('department');
  const revenueReports = filterReports('revenue');
  const expenseReports = filterReports('expense');

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS }
    >
      <li className={ `${styles['navigation-item-header-title']}` }>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS.toUpperCase() }
        </span>
      </li>
      {managementReports.length > 0 && (
        <ServiceSubMenu
          hasWiderSubmenuList
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.MANAGEMENT }
        >
          {
            managementReports.map((report, index) => (
              <ServiceItem
                key={ report.id }
                wrapText
                hasBorderBottom={ managementReports.length - 1 == index }
                serviceUrl={ viewReportPath(business.id, report.slug) }
                title={ report.name }
              />
            ))
          }
          <ServiceItem
            serviceUrl={ managementReportsPath(businessId) }
            title="Explore All"
          />
        </ServiceSubMenu>
      )}
      {departmentReports.length > 0 && (
        <ServiceSubMenu
          hasBorderBottom
          hasWiderSubmenuList
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.DEPARTMENT }
        >
          {
            departmentReports.map((report, index) => (
              <ServiceItem
                key={ report.id }
                wrapText
                hasBorderBottom={ departmentReports.length - 1 == index }
                serviceUrl={ viewReportPath(business.id, report.slug) }
                title={ report.name }
              />
            ))
          }
          <ServiceItem
            serviceUrl={ departmentReportsPath(businessId) }
            title="Explore All"
          />
        </ServiceSubMenu>
      )}
      {revenueReports.length > 0 && (
        <ServiceSubMenu
          hasWiderSubmenuList
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.REVENUE }
        >
          {
            revenueReports.map((report, index) => (
              <ServiceItem
                key={ report.id }
                wrapText
                hasBorderBottom={ revenueReports.length - 1 == index }
                serviceUrl={ viewReportPath(business.id, report.slug) }
                title={ report.name }
              />
            ))
          }
          <ServiceItem
            serviceUrl={ revenueReportsPath(businessId) }
            title="Explore All"
          />
        </ServiceSubMenu>
      )}
      {expenseReports.length > 0 && (
        <ServiceSubMenu
          hasWiderSubmenuList
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.EXPENSE }
        >
          {
            expenseReports.map((report) => (
              <ServiceItem
                key={ report.id }
                wrapText
                serviceUrl={ viewReportPath(business.id, report.slug) }
                title={ report.name }
              />
            ))
          }
        </ServiceSubMenu>
      )}
      {canVisibleReportService && (
      <ServiceItem
        serviceUrl={ laborReportsPath(businessId) }
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.LABOR }
      />
      )}
      {canVisibleReportService && (
      <ServiceItem
        serviceUrl={ budgetsPath(businessId) }
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.BUDGET_FORECAST }
      />
      )}
      {canVisibleMetricsService && (
      <ServiceItem
        serviceUrl={ metricsPath(businessId) }
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.INDUSTRY_METRICS }
      />
      )}
    </ModuleHeaderMenu>
  );
};

export default ReportsItem;
