import React from 'react';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { Button } from '@src/components/ui_v2/buttons';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';

interface AuditScanButtonProps {
  status?: AuditScanDetails['status'];
  onRunAudit: () => void;
}

const AuditScanButton = ({ status, onRunAudit }: AuditScanButtonProps) => {
  if (!status) {
    return null;
  }

  if (status === 'in_progress') {
    return <ProgressActivityLoader size="large" />;
  }

  const isRerun = status === 'completed';
  return (
    <Button
      size="compact"
      variant="outline"
      onClick={ onRunAudit }
    >
      {isRerun ? 'Re-run Audit Scan' : 'Run Audit Scan'}
    </Button>
  );
};

export default AuditScanButton;
