/* eslint-disable import/order */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUpdateReportDatas } from '@src/hooks/queries/report_service/report_datas';
import { IReport } from '@src/types/report_service/report';
import {
  endOfMonthApiDate,
  endOfWeekApiDate,
  formatApiDate,
  formatDate,
  parseApiDate,
  startOfMonthApiDate,
  startOfWeekApiDate,
} from '@src/utils/date_helpers';

import ClickTooltip from '@src/components/common_v2/click_tooltip/click_tooltip';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { useFilterData } from '@src/components/ui_v2/filter';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { ReconciliationOutlineIcon } from '@src/components/utils/icomoon';
import Icomoon from '@src/components/utils/icomoon/icomoon';
import { useSection } from '@src/components/utils_v2/section';
import { reportRefreshState, reportRefreshJobId, reportDataPeriodType, reportsAccountingMethod } from '../../atoms';

import { IReportDatasCollection, isBasicReport, TFilterData } from '../hooks';

import styles from '../styles.module.scss';

const ReportRefreshStatus = ({
  collection,
  report,
  reportEstimateEnabled=false,
}: {
  collection: IReportDatasCollection;
  report: IReport;
  reportEstimateEnabled?: boolean;
}) => {
  const section = useSection();
  const filterData: TFilterData = useFilterData(section);
  const { mutate } = useUpdateReportDatas();

  const [isApiInProgress, setIsApiInProgress] = useState(false);
  const [showError, setShowError] = useState(false);
  const [update, setUpdate] = useRecoilState(reportRefreshState);
  const [jobId, setJobId] = useRecoilState(reportRefreshJobId);

  const periodType = useRecoilValue(reportDataPeriodType);
  const [refreshError, setRefreshError] = useState({ error: false, message: '' });
  const basicReport = isBasicReport(report);
  const [accountingMethod, updateAccountingMethod] = useRecoilState(reportsAccountingMethod);

  const startDate = useMemo(() => {
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
      return formatApiDate(formatDate(filterData?.reportDataRange?.gte));
    }
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY) {
      return startOfWeekApiDate(parseApiDate(filterData?.reportDataRange?.gte));
    }

    return startOfMonthApiDate(parseApiDate(filterData?.reportDataRange?.gte));
  }, [periodType, filterData]);

  const endDate = useMemo(() => {
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
      return formatApiDate(formatDate(filterData?.reportDataRange?.lte));
    }
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY) {
      return endOfWeekApiDate(parseApiDate(filterData?.reportDataRange?.lte));
    }

    return endOfMonthApiDate(parseApiDate(filterData?.reportDataRange?.lte));
  }, [periodType, filterData]);

  const { minUpdatedAtString, minUpdatedAt } = useMemo(() => {
    let updatedAtString = collection?.records?.[0]?.updatedTimeString;
    let tempUpdatedAt = moment(new Date(3000, 1, 1));

    collection?.records?.forEach((reportData) => {
      if (reportData.updatedAt) {
        const currentUpdatedAt = moment(reportData.updatedAt);

        if (tempUpdatedAt >= currentUpdatedAt) {
          tempUpdatedAt = currentUpdatedAt;
          updatedAtString = reportData.updatedTimeString;
        }
      }
    });

    const updatedAt = tempUpdatedAt.format('ddd, MMM D YYYY, h:mm A');

    return { minUpdatedAtString: updatedAtString, minUpdatedAt: updatedAt };
  }, [collection.records]);

  const handleClick = useCallback(() => {
    if (startDate !== '' && endDate !== '') {
      if (basicReport) {
        updateAccountingMethod({
          method: accountingMethod.method,
          timestamp: new Date().getTime(),
        });
        return;
      }
      setIsApiInProgress(true);
      mutate({
        reportId:        report.id,
        startDate,
        endDate,
        periodType,
        latestUpdatedAt: minUpdatedAt,
      }, {
        onSuccess: (data) => {
          setUpdate(true);
          setIsApiInProgress(false);
          if (reportEstimateEnabled) {
            setJobId(data?.jobId || '');
          }
        },
        onError: () => {
          setIsApiInProgress(false);
        },
      });
    }
  }, [
    mutate,
    report.id,
    startDate,
    endDate,
    periodType,
    minUpdatedAt,
    setUpdate,
    setJobId,
    reportEstimateEnabled,
  ]);

  useEffect(() => {
    const updatingDatas = collection?.records?.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_QUEUED
      || reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_STARTED
    ));
    setUpdate(updatingDatas?.length !== 0);
    if (jobId) {
      setJobId('');
    }

    if (collection?.records) {
      const failedData = collection?.records?.filter((reportData) => (
        reportData.updateState
        === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_FAILED
      ));
      const failed = failedData.length !== 0;
      if (failed) {
        setRefreshError({ error: true, message: failedData[0]?.errorMsg || '' });

        setTimeout(() => {
          setRefreshError({ error: false, message: '' });
        }, 10000);
      }
    }
  }, [collection.records, setUpdate, setRefreshError]);

  if (collection?.query?.isLoading || isApiInProgress) {
    return <SpinnerIcon spin />;
  }

  if (refreshError.error) {
    return (
      <div className={ styles['update-info-area'] }>
        <ClickTooltip
          hideButtons
          content={ (
            <div
              className={ styles['report-refresh-error-title'] }
              role="presentation"
              onClick={ () => setShowError(!showError) }
            >
              <Text className="m-r-5">Error</Text>
              <Icomoon
                iconName="info-circle"
              />
            </div>
          ) }
          showTooltip={ showError }
          title={
            <Text as="span" className={ styles['report-refresh-error'] } dangerouslySetInnerHTML={ { __html: refreshError.message } } />
          }
          onClick={ setShowError }
        />
      </div>
    );
  }

  return (
    <div className={ styles['update-info-area'] }>
      {update ? (
        <>
          <Text className={ styles['update-value-txt'] }>
            Update in progress
          </Text>
          <SpinnerIcon spin icon="refresh" />
        </>
      ) : (
        <>
          <Text className={ styles['update-title'] }>
            Last Updated:
          </Text>

          {minUpdatedAtString && (
            <Text className={ styles['update-value-txt'] }>
              {minUpdatedAtString}
            </Text>
          )}
          <Button
            prefixIcon={ <ReconciliationOutlineIcon color="black" fontSize={ 15 } fontVariant="semi-bold" /> }
            variant="link"
            onClick={ handleClick }
          />
        </>
      )}
    </div>
  );
};

export default ReportRefreshStatus;
