import React from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import styles from './styles.module.scss';

export const UpgradeSubscriptionAction: React.FC = () => {
  return (
    <div className={ styles['upgrade-section'] }>
      <ActionsDropdown.Action
        name="🚀 Upgrade to Full Bookkeeping"
        onClick={ () => window.open('https://docyt.com/contact', '_blank') }
      />
    </div>
  );
};

export default UpgradeSubscriptionAction;
