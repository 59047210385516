import React, { useMemo } from 'react';

import { TAuditScanStatus } from '@src/types/operational_dashboard/audit_scan';

import AuditScanTooltip from '@src/components/operational_dashboard/components/tooltips/audit_scan_tooltip';
import { CheckMarkIcon } from '@src/components/utils/icomoon';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import NumberBox from './number_box';
import { IListItem } from '../../types';

interface IReconciliationItemProps {
  tooltipData: IListItem
  value: number;
  lastAuditedOn?: string | number;
  auditScanStatus: TAuditScanStatus
}

const AuditScanItem = ({
  tooltipData,
  value,
  lastAuditedOn,
  auditScanStatus,
}: IReconciliationItemProps) => {
  const renderValue = useMemo(() => {
    if (auditScanStatus === 'completed') {
      return <CheckMarkIcon color="gray" fontSize={ 20 } />;
    }
    return <NumberBox value={ { value, type: 'number' } } />;
  }, [value, auditScanStatus]);

  return (
    <HoverTooltipWrapper
      content={ (
        value !== undefined && (
          <AuditScanTooltip
            auditScanCount={ value }
            item={ tooltipData }
            lastAuditedOn={ lastAuditedOn }
          />
        )
      ) }
    >
      {renderValue}
    </HoverTooltipWrapper>
  );
};

export default AuditScanItem;
