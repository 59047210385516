import React, { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { verifyEmail, validateEmail } from '@src/requests/signup';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Form from '@src/components/ui_v2/form';

import OnboardingForm from '../layout/onboarding_form';
import { useOnboarding } from '../provider';
import { ISignUpFormValues, signUpValidation } from '../shema';

import styles from '../styles.module.scss';

interface ISignupFormProps {
  formId: string;
}

const SignupForm = ({ formId }: ISignupFormProps) => {
  const { setPage, setEmail, setFullName, setEmailAccessToken } = useOnboarding();
  const [errMsg, setErrMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ISignUpFormValues>({
    defaultValues: { fullName: '', email: '' },
    resolver: yupResolver(signUpValidation),
  });

  const onSubmit = useCallback(
    (data: ISignUpFormValues) => {
      setErrMsg('');
      setIsLoading(true);

      validateEmail(data.email)
        .then(() => {
          verifyEmail(data.email)
            .then((response) => {
              setIsLoading(false);
              setEmail(data.email);
              setFullName(data.fullName);
              setEmailAccessToken(response.emailAccessToken);
              setPage('email_confirm');
            })
            .catch((error) => {
              setIsLoading(false);
              setErrMsg(error.message);
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setErrMsg(error.message);
        });
    },
    [setPage, setErrMsg, setIsLoading, setEmail, setFullName, setEmailAccessToken]
  );

  const handleSignIn = useCallback(() => {
    Backbone.history.navigate('/sign_in', { trigger: true });
  }, []);

  return (
    <>
      {errMsg !== '' && <ErrorNotification message={errMsg} />}
      {isLoading && <Spinner />}
      <OnboardingForm
        backButtonLabel="Sign In"
        formId={ formId }
        handleSubmit={ handleSubmit }
        nextButtonLabel="Next"
        onClickBackButton={ handleSignIn }
        onSubmit={ onSubmit }
      >
        <div className="display-flex p-b-20 font-16 justify-content-center">
          Create your account
        </div>
        <div className={ styles['padding-wrapper'] }>
          <span className={ styles['form-label'] }>Full Name</span>
          <Form.TextField
            hiddenLabel
            hideClear
            className={ styles['form-value name-value'] }
            error={ errors.fullName?.message }
            label="Full Name"
            { ...register('fullName') }
          />
        </div>
        <div className={ styles['padding-wrapper'] }>
          <span className={ styles['form-label'] }>Email Address</span>
        </div>
        <Form.TextField
          hiddenLabel
          hideClear
          className={ styles['form-value'] }
          error={ errors.email?.message }
          label="Email"
          prefixIcon={ <span className="sign-up-mail-icon icon-mail" /> }
          { ...register('email') }
        />
        <p className={ styles['onboarding-note'] }>
          By continuing with your email id, you accept
          { ' ' }
          <a href="https://www.docyt.com/terms/" rel="noreferrer" target="_blank">
            Docyt&apos;s Terms
          </a>
          { ' ' }
          &
          { ' ' }
          <a href="https://www.docyt.com/privacy/" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
          .
        </p>
      </OnboardingForm>
    </>
  );
};

export default SignupForm;
