import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { flatten } from 'lodash';

import { useGetClientBusinesses } from '@src/hooks/queries/client_businesses';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useLocalStorage } from '@src/hooks/storage';
import { useSorting } from '@src/hooks/url_params';
import { TClientBusinessesSortColumn } from '@src/types/clients';
import { ISortingParams } from '@src/types/sorting';

import SearchResult from '@src/components/client_dashboard/main_details/search_result';
import { OperationalDashboardTableList } from '@src/components/operational_dashboard';
import { Button } from '@src/components/ui_v2/buttons';
import QueryStatus from '@src/components/utils/query_status';

import Body from './body';
import Header from './header';

import styles from './styles.module.scss';

const DefaultSorting: ISortingParams<TClientBusinessesSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

const DetailsPage = (): JSX.Element => {
  const { data: isMultiBusinessDashboardEnabled } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.MULTI_BUSINESS_DASHBOARD);
  const { storedValue, setValue } = useLocalStorage('switch_to_new_dashboard', {
    switch_to_new_dashboard: false,
  });
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const [dashboardUpldated, setDashboardUpldated] = useState<boolean>(false);

  const sorting = useSorting<TClientBusinessesSortColumn>({ defaultSorting: DefaultSorting });

  const query = useGetClientBusinesses({
    searchQuery,
    ...sorting.data,
  });

  const { refetch: refetchClientBusinesses } = query;

  const clientBusinessesPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const clientBusinesses = useMemo(() => {
    return flatten(clientBusinessesPages.map((p) => p.collection));
  }, [clientBusinessesPages]);

  const totalCount = useMemo(() => {
    return clientBusinessesPages[0]?.meta.totalCount;
  }, [clientBusinessesPages]);

  const searchCount = useMemo(() => {
    return clientBusinessesPages[0]?.meta.searchCount;
  }, [clientBusinessesPages]);

  const handleDashboardUpdated = useCallback(() => {
    setDashboardUpldated(true);
  }, []);

  const handleClickReload = useCallback(() => {
    setDashboardUpldated(false);
    refetchClientBusinesses();
  }, [refetchClientBusinesses]);

  useEffect(() => {
    window.Docyt.vent.on('docyt:faye:notification', handleDashboardUpdated);

    return () => {
      window.Docyt.vent.off('docyt:faye:notification');
    };
  }, [handleDashboardUpdated]);

  const handleSwitchToNewDashboard = useCallback(() => {
    setValue({ switch_to_new_dashboard: true });
  }, [setValue]);

  const switchToOldDashboard = useCallback(() => {
    setValue({ switch_to_new_dashboard: false });
  }, [setValue]);

  return (
    <>
      <QueryStatus query={ query } />
      <div className={ styles['client-dashboard-details-page'] }>
        <div className={ styles['client-dashboard-details-header'] }>
          <Header
            isMultiBusinessDashboardEnabled={ isMultiBusinessDashboardEnabled || false }
            searchQuery={ searchQuery }
            switchToNewDashboard={ storedValue.switch_to_new_dashboard }
            onSearched={ setSearchQuery }
            onSwitchToNewDashboard={ storedValue.switch_to_new_dashboard ? switchToOldDashboard : handleSwitchToNewDashboard }
          />
        </div>
        <div className={ styles['body-region'] }>
          {
            dashboardUpldated && (
              <Button
                className={ styles['dashboard-reload-button'] }
                variant="primary"
                onClick={ handleClickReload }
              >
                Dashboard has been updated. Click here to reload
              </Button>
            )
          }
          {
            searchQuery && (
              <SearchResult searchCount={ searchCount } searchQuery={ searchQuery } />
            )
          }
          {
            query.isFetched && (
              storedValue.switch_to_new_dashboard ? (
                <OperationalDashboardTableList />
              ) : (
                <Body
                  clientBusinesses={ clientBusinesses }
                  query={ query }
                  searchCount={ searchCount }
                  sorting={ sorting }
                  totalCount={ totalCount }
                />
              )
            )
          }
        </div>
      </div>
    </>
  );
};

export default DetailsPage;
